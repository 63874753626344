import { message } from "antd";
import { call, put } from "redux-saga/effects";
// import { TemplateSelectors } from '../Redux/TemplateRedux'
import RegisterAction from "../reducers/RegisterRedux";
export function* registerSiswaRequest(api, action) {
  const { data } = action;
  const history = action.data.history;
  const response = yield call(api.signUpRequest, data);
  if (response.ok) {
    //console.log("register response.data", response.data);
    yield put(RegisterAction.registerSiswaSuccess(response.data));
    message.success("Berhasil mendaftar");
    history.goBack();
  } else {
    yield put(RegisterAction.registerSiswaFailure());
  }
}
