import React, { useEffect } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { config } from "../../../../data/DataGrafikTreeTest";
import styled from "styled-components";
import Container from "../../../../components/TestingApp/Container";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { useRouteMatch } from "react-router-dom";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";

const Grafik = () => {
  const hasilState = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();

  const datagrafik = hasilState.payloadGrafik.map((e) => ({
    label: `Page ${e.page}`,
    value: e.waktu,
  }));

  config.dataProvider = datagrafik;
  useEffect(() => {
    dispatch(
      HasilActions.hasilGrafikRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);

  return (
    <Container title="GRAFIK HASIL TREE TEST" header={true}>
      {hasilState.fetching ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <AmCharts.React
          style={{ width: "100%", height: "500px", marginTop: "40px" }}
          options={config}
        />
      )}
    </Container>
  );
};

export default Grafik;

export const ContainerPsikotes = styled.div`
  /* font-family: "Poppins"; */
  font-weight: bold;
  width: 100% !important;
  display: grid;
  justify-content: center;
  align-items: start;
  color: white;

  @media screen and (max-width: 1200px) {
    width: max-content;
    justify-content: center;
    display: grid;
  }
  @media screen and (max-width: 868px) {
    width: max-content;
  }
`;

// const TitleBox = styled.div`
//   border-radius: 0px 0px 10px 10px;
//   background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
//   box-sizing: border-box;
//   align-items: center;
//   justify-content: center;
//   display: grid;
//   color: white;
//   height: 50px;
//   width: 500px;
//   position: relative;
//   margin-bottom: -6rem;
// `;
// const BoxChart = styled.div`
//   box-sizing: border-box;
//   display: grid;
//   align-items: center;
//   justify-content: center;
//   border: 4px solid #0e7178;
//   width: 1400px;
//   height: 680px;
//   border-radius: 10px;
//   background: white;
// `;
