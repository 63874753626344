import React, { useState } from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
// import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
// import AppNotification from "../../components/AppNotification";
// import MailNotification from "../../components/MailNotification";
// import Auxiliary from "util/Auxiliary";
import "./CustomTopbar.css";

import {
  // NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

// const { Header } = Layout;

const Topbar = () => {
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  // const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  // const languageMenu = () => (
  //   <CustomScrollbars className="gx-popover-lang-scroll">
  //     <ul className="gx-sub-popover">
  //       {languageData.map((language) => (
  //         <li
  //           className="gx-media gx-pointer"
  //           key={JSON.stringify(language)}
  //           onClick={() => dispatch(switchLanguage(language))}
  //         >
  //           <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
  //           <span className="gx-language-text">{language.name}</span>
  //         </li>
  //       ))}
  //     </ul>
  //   </CustomScrollbars>
  // );

  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value);
  // };

  // ambil pathname untuk title di topbar
  let pathName = window.location.pathname.slice(1);
  const soalState = useSelector((state) => state.soal);
  const eventState = useSelector((state) => state.event);

  // ganti nama topbar yang tidak diinginkan menjadi kosong di topbar
  let topbarTitle = pathName
    .replace("-", " ")
    .replace("/", " ")
    .replace("%20", " ")
    .replace(soalState.dataSoalBerlangsung?.event_id, "")
    .replace(eventState.payloaddetail?._id, "")
    .toUpperCase();
  // //console.log("dari topbar: ");
  // //console.log(soalState.dataSoalBerlangsung.event_id);

  return (
    <div className="custom-topbar-wrap">
      <div
        // style={{
        //   width: "100%",
        //   height: "138px",
        //   display: "flex",
        //   justifyContent: "start",
        //   alignItems: "center",
        //   padding: "0 32px",
        //   background: "#fff",
        //   position: "relative",
        //   zIndex: "1",
        // }}
        className="custom-topbar shadow-sm"
      >
        {(navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
        {window.screen.availWidth > 720 ? (
          <>
            {/* <div
              style={{
                minWidth: "240.51px",
                padding: "0 20px",
                height: "40.9px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "linear-gradient(90deg, #353b7b -15.53%, #097a82 91.3%)",
                boxShadow: "0px 3.55659px 3.55659px rgba(0, 0, 0, 0.25)",
                borderRadius: "9.78063px",
              }}
            >
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "27px",

                  color: "#FFFFFF",
                }}
              >
                {topbarTitle}
              </div>
            </div> */}
            <img
              src={"assets/images/icons/topbar/" + pathName + ".png"}
              alt=""
              style={{
                marginLeft: "-28px",
                border: "none",
              }}
            ></img>

            <div
              style={{
                fontWeight: "700",
                fontSize: window.screen.availWidth > 991 ? "42.6791px" : "20px",
                lineHeight: "64px",

                marginLeft: "50px",

                background:
                  "linear-gradient(to right, #c0c0c0, #f0f0f0, #c0c0c0)",
                // "linear-gradient(to right, #314a80, #182848, #314a80)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
                // "linear-gradient(90.67deg, #314a80 -22.73%, #182848 121.43%)"
              }}
            >
              {/* <Link to={"/dashboard"}>SMKN 1 KOTA BEKASI</Link> */}
              {/* <Link to={"/dashboard"}>FBKK KOTA BEKASI</Link> */}
              <Link to={"/dashboard"}>DAYA UJI</Link>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* <div
            style={{
              fontWeight: "700",
              fontSize: "22vw",
              lineHeight: "64px",

              marginLeft: "50px",

              background:
                "linear-gradient(90.67deg, #00F0FF -22.73%, #001213 121.43%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }}
          >
            <Link to={"/dashboard"}>SMK N 1 KOTA BEKASI</Link>
          </div> */}

            <div
              style={{
                minWidth: "80%",
                height: "40.9px",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                background:
                  "linear-gradient(90deg, #00F0FF -15.53%, #00AEB9 91.3%)",
                boxShadow: "0px 3.55659px 3.55659px rgba(0, 0, 0, 0.25)",
                borderRadius: "9.78063px",
              }}
            >
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "17.783px",
                  lineHeight: "27px",

                  color: "#FFFFFF",
                }}
              >
                {topbarTitle}
              </div>
            </div>
            <div
              style={{
                marginLeft: "20px",
                width: "48px",
                height: "48px",
              }}
            >
              <UserInfo />
            </div>
          </div>
        )}

        {/* {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={"/assets/images/w-logo.png"} />
      </Link> */}

        {/* <SearchBox
        styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
        placeholder="Search in app..."
        onChange={updateSearchChatUser}
        value={searchText}
      /> */}
        {/* <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={
              <SearchBox
                styleName="gx-popover-search-bar"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                value={searchText}
              />
            }
            trigger="click"
          >
            <span className="gx-pointer gx-d-block">
              <i className="icon icon-search-new" />
            </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </Auxiliary>
        )}
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                   trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li>
      </ul> */}
      </div>
    </div>
  );
};

export default Topbar;
