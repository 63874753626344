export const config = {
  type: "serial",
  theme: "light",
  marginRight: 40,
  marginLeft: 40,
  autoMarginOffset: 20,
  mouseWheelZoomEnabled: false,
  dataDateFormat: "YYYY-MM-DD",
  hideCredits: true,

  valueAxes: [
    {
      id: "v1",
      axisAlpha: 0,
      position: "left",
      ignoreAxisWidth: true,
    },
  ],
  balloon: {
    borderThickness: 1,
    shadowAlpha: 0,
  },
  graphs: [
    {
      id: "g1",
      balloon: {
        drop: true,
        adjustBorderColor: false,
        color: "#ffffff",
      },
      bullet: "round",
      bulletBorderAlpha: 1,
      bulletColor: "#FFFFFF",
      bulletSize: 5,
      hideBulletsCount: 50,
      lineThickness: 2,
      title: "Line Tree Tes",
      useLineColorForBulletBorder: true,
      valueField: "value",
      balloonText: "<span style='font-size:18px;'>[[value]]</span>",
    },
  ],
  chartCursor: {
    pan: true,
    valueLineEnabled: true,
    valueLineBalloonEnabled: true,
    cursorAlpha: 1,
    cursorColor: "#258cbb",
    limitToGraph: "g1",
    valueLineAlpha: 0.2,
    valueZoomable: true,
  },
  categoryField: "label",
  categoryAxis: {
    parseDates: false,
    dashLength: 2,
    minorGridEnabled: true,
  },
  // "dataProvider": [{
  //   "label": "Short Them Memory",
  //   "value": 10
  // }, {
  //   "value": 10
  // }, {
  //   "value": 10
  // }, {
  //   "value": 20
  // }, {
  //   "value": 20
  // }]
};
