import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  jenisSoalRequest: ["data"],
  jenisSoalSuccess: ["payload"],
  jenisSoalFailure: null,

  getPsikotesRequest: ["data"],
  getPsikotesSuccess: ["payload"],
  getPsikotesFailure: null,

  getTreetesRequest: ["data"],
  getTreetesSuccess: ["payload"],
  getTreetesFailure: null,

  getPageRequest: ["data"],
  getPageSuccess: ["payload"],
  getPageFailure: null,

  deletePageRequest: ["data"],
  deletePageSuccess: null,
  deletePageFailure: null,

  nextPageRequest: ["data"],
  nextPageSuccess: ["payload"],
  nextPageFailure: null,

  saveJawabanRequest: ["data"],
  saveJawabanSuccess: null,
  saveJawabanFailure: null,

  getTimeRequest: ["data"],
  getTimeSuccess: ["payload"],
  getTimeFailure: null,

  saveTimeRequest: ["data"],
  saveTimeSuccess: ["payload"],
  saveTimeFailure: null,

  refreshTimeRequest: ["data"],
  refreshTimeSuccess: ["payload"],
  refreshTimeFailure: null,

  soalBerlangsungRequest: ["data"],
  soalBerlangsungSuccess: ["payload"],
  soalBerlangsungFailure: null,

  saveGrafikRequest: ["data"],
  saveGrafikSuccess: null,
  saveGrafikFailure: null,

  getAllSoalRequest: ["data"],
  getAllSoalSuccess: ["payload"],
  getAllSoalFailure: null,

  getSoalEventRequest: ["data"],
  getSoalEventSuccess: ["payload"],
  getSoalEventFailure: null,

  addSoalEventRequest: ["data"],
  addSoalEventSuccess: null,
  addSoalEventFailure: null,

  deleteSoalEventRequest: ["data"],
  deleteSoalEventSuccess: null,
  deleteSoalEventFailure: null,

  deleteAllSoalEventRequest: ["data"],

  createSoalRequest: ["data"],
  createSoalSuccess: null,
  createSoalFailure: null,

  deleteSoalRequest: ["data"],
  deleteSoalSuccess: null,
  deleteSoalFailure: null,
});

export const SoalTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: [],
  error: null,

  dataSoal: null,
  payloadSoal: null,

  datapage: null,
  payloaddatapage: null,

  datanextpage: null,

  datajawaban: null,

  dataTime: null,
  payloadTime: null,

  dataTreetes: null,
  payloadTreetes: null,

  dataSoalBerlangsung: null,
  soalBerlangsung: null,

  dataGrafik: null,

  dataAllSoal: null,
  payloadAllSoal: [],
  totalSoal: null,

  dataEventSoal: null,
  payloadEventSoal: [],
  fetchingEventSoal: null,

  dataAddEventSoal: null,

  dataCreateSoal: null,

  dataDeleteSoal: null,
});

/* ------------- Selectors ------------- */

export const SoalSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data });

// successful api lookup
export const success = (state, action) => {
  const { payload } = action;
  return state.merge({ fetching: false, error: null, payload });
};

// Something went wrong somewhere.
export const failure = (state) => state.merge({ fetching: false, error: true });

export const requestPsikotes = (state, action) =>
  state.merge({ fetching: true, dataSoal: action.data });

export const successPsikotes = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadSoal: action.payload,
  });
};

export const requestTreetes = (state, action) =>
  state.merge({ fetching: true, dataTreetes: action.data });

// successful api lookup treetes
export const successTreetes = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadTreetes: action.payload,
  });
};

// warttegtes
// export const requestWarttegtes = (state, action) =>
//   state.merge({ fetching: true, dataWarttegtes: action.data });

// // successful api lookup warttegtes
// export const successWarttegtes = (state, action) => {
//   return state.merge({
//     fetching: false,
//     error: null,
//     payloadwartteg: action.payload,
//   });
// };

export const requestdatapage = (state, action) =>
  state.merge({ fetching: true, datapage: action.data });

// successful api lookup
export const successdatapage = (state, action) => {
  // const { payload } = action;
  return state.merge({
    fetching: false,
    error: null,
    payloaddatapage: action.payload,
  });
};

export const requestdeletedatapage = (state, action) =>
  state.merge({ fetching: true, datapage: action.data });

// successful api lookup
export const successdeletedatapage = (state, action) => {
  // const { payload } = action;
  return state.merge({
    fetching: false,
    error: null,
  });
};

export const requestnextdatapage = (state, action) =>
  state.merge({ fetching: true, datanextpage: action.data });

// Something went wrong somewhere.

export const requestSaveJawaban = (state, action) =>
  state.merge({ fetching: true, datajawaban: action.data });

// successful api lookup
export const successSaveJawaban = (state, action) => {
  return state.merge({ fetching: false, error: null });
};

export const requestTime = (state, action) =>
  state.merge({ fetching: true, dataTime: action.data });

// successful api lookup
export const successTime = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadTime: action.payload,
  });
};

export const requestSaveTime = (state, action) =>
  state.merge({
    // fetching: true,
    dataTime: action.data,
  });

// successful api lookup
export const successSaveTime = (state, action) => {
  return state.merge({
    // fetching: false,
    error: null,
    payloadTime: action.payload,
  });
};

export const requestSB = (state, action) =>
  state.merge({
    fetching: true,
    dataSoalBerlangsung: action.data,
  });

// successful api lookup
export const successSB = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    soalBerlangsung: action.payload,
  });
};

export const requestDataGrafik = (state, action) =>
  state.merge({
    fetching: true,
    dataGrafik: action.data,
  });

// successful api lookup
export const successDataGrafik = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
  });
};

export const requestAllSoal = (state, action) =>
  state.merge({ fetching: true, dataAllSoal: action.data });

// successful api lookup
export const successAllSoal = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadAllSoal: action.payload.data,
    totalSoal: action.payload.total,
  });
};

export const requestEventSoal = (state, action) =>
  state.merge({ fetchingEventSoal: true, dataEventSoal: action.data });

// successful api lookup
export const successEventSoal = (state, action) => {
  return state.merge({
    fetchingEventSoal: false,
    error: null,
    payloadEventSoal: action.payload,
  });
};

export const requestAddEventSoal = (state, action) =>
  state.merge({ fetching: true, dataAddEventSoal: action.data });

// successful api lookup
export const successAddEventSoal = (state) => {
  return state.merge({
    fetching: false,
    error: null,
  });
};

export const requestDeleteEventSoal = (state, action) =>
  state.merge({ fetching: true, dataAddEventSoal: action.data });

// successful api lookup
export const successDeleteEventSoal = (state) => {
  return state.merge({
    fetching: false,
    error: null,
  });
};

export const requestCreateSoal = (state, action) =>
  state.merge({ fetching: true, dataCreateSoal: action.data });

// successful api lookup
export const successCreateSoal = (state) => {
  return state.merge({
    fetching: false,
    error: null,
  });
};

export const requestDeleteSoal = (state, action) =>
  state.merge({ fetching: true, dataDeleteSoal: action.data });

// successful api lookup
export const successDeleteSoal = (state) => {
  return state.merge({
    fetching: false,
    error: null,
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.JENIS_SOAL_REQUEST]: request,
  [Types.JENIS_SOAL_SUCCESS]: success,
  [Types.JENIS_SOAL_FAILURE]: failure,

  [Types.GET_PSIKOTES_REQUEST]: requestPsikotes,
  [Types.GET_PSIKOTES_SUCCESS]: successPsikotes,
  [Types.GET_PSIKOTES_FAILURE]: failure,

  [Types.GET_TREETES_REQUEST]: requestTreetes,
  [Types.GET_TREETES_SUCCESS]: successTreetes,
  [Types.GET_TREETES_FAILURE]: failure,

  // [Types.GET_WARTTEGTES_REQUEST]: requestWarttegtes,
  // [Types.GET_WARTTEGTES_SUCCESS]: successWarttegtes,
  // [Types.GET_WARTTEGTES_FAILURE]: failure,

  [Types.GET_PAGE_REQUEST]: requestdatapage,
  [Types.GET_PAGE_SUCCESS]: successdatapage,
  [Types.GET_PAGE_FAILURE]: failure,

  [Types.DELETE_PAGE_REQUEST]: requestdeletedatapage,
  [Types.DELETE_PAGE_SUCCESS]: successdeletedatapage,
  [Types.DELETE_PAGE_FAILURE]: failure,

  [Types.NEXT_PAGE_REQUEST]: requestnextdatapage,
  [Types.NEXT_PAGE_SUCCESS]: successdatapage,
  [Types.NEXT_PAGE_FAILURE]: failure,

  [Types.SAVE_JAWABAN_REQUEST]: requestSaveJawaban,
  [Types.SAVE_JAWABAN_SUCCESS]: successSaveJawaban,
  [Types.SAVE_JAWABAN_FAILURE]: failure,

  [Types.GET_TIME_REQUEST]: requestTime,
  [Types.GET_TIME_SUCCESS]: successTime,
  [Types.GET_TIME_FAILURE]: failure,

  [Types.SAVE_TIME_REQUEST]: requestSaveTime,
  [Types.SAVE_TIME_SUCCESS]: successSaveTime,
  [Types.SAVE_TIME_FAILURE]: failure,

  [Types.REFRESH_TIME_REQUEST]: requestTime,
  [Types.REFRESH_TIME_SUCCESS]: successTime,
  [Types.REFRESH_TIME_FAILURE]: failure,

  [Types.SOAL_BERLANGSUNG_REQUEST]: requestSB,
  [Types.SOAL_BERLANGSUNG_SUCCESS]: successSB,
  [Types.SOAL_BERLANGSUNG_FAILURE]: failure,

  [Types.SAVE_GRAFIK_REQUEST]: requestDataGrafik,
  [Types.SAVE_GRAFIK_SUCCESS]: successDataGrafik,
  [Types.SAVE_GRAFIK_FAILURE]: failure,

  [Types.GET_ALL_SOAL_REQUEST]: requestAllSoal,
  [Types.GET_ALL_SOAL_SUCCESS]: successAllSoal,
  [Types.GET_ALL_SOAL_FAILURE]: failure,

  [Types.GET_SOAL_EVENT_REQUEST]: requestEventSoal,
  [Types.GET_SOAL_EVENT_SUCCESS]: successEventSoal,
  [Types.GET_SOAL_EVENT_FAILURE]: failure,

  [Types.ADD_SOAL_EVENT_REQUEST]: requestAddEventSoal,
  [Types.ADD_SOAL_EVENT_SUCCESS]: successAddEventSoal,
  [Types.ADD_SOAL_EVENT_FAILURE]: failure,

  [Types.DELETE_SOAL_EVENT_REQUEST]: requestDeleteEventSoal,
  [Types.DELETE_SOAL_EVENT_SUCCESS]: successDeleteEventSoal,
  [Types.DELETE_SOAL_EVENT_FAILURE]: failure,

  [Types.DELETE_ALL_SOAL_EVENT_REQUEST]: requestDeleteEventSoal,

  [Types.CREATE_SOAL_REQUEST]: requestCreateSoal,
  [Types.CREATE_SOAL_SUCCESS]: successCreateSoal,
  [Types.CREATE_SOAL_FAILURE]: failure,

  [Types.DELETE_SOAL_REQUEST]: requestDeleteSoal,
  [Types.DELETE_SOAL_SUCCESS]: successDeleteSoal,
  [Types.DELETE_SOAL_FAILURE]: failure,
});
