// import { display } from "@mui/system";
import { Col, Row, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";
import "./psikotes.css";

const Hasil = () => {
  const hasilstate = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  useEffect(() => {
    dispatch(
      HasilActions.soalRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
      })
    );
    dispatch(
      HasilActions.jawabanRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
        limit: 10000,
      })
    );
    dispatch(
      HasilActions.getHasilRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);
  return (
    <div style={{ width: "100%", padding: 20 }}>
      {hasilstate.fetching ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row>
            <Col
              span={24}
              style={{
                height: 50,
                backgroundColor: "black",
                marginBottom: 20,
                borderRadius: 20,
                border: "4px solid #0e7178",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 10,
                  top: 0,
                  bottom: 0,
                  zIndex: 999999,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h5 style={{ color: "white" }}>
                  Score :{" "}
                  {hasilstate.payloadHasil && hasilstate.payloadHasil.nilai}%
                </h5>
              </div>

              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  alignItems: "center",
                  width: `${
                    hasilstate.payloadHasil && hasilstate.payloadHasil.nilai
                  }%`,
                  height: "100%",
                  background:
                    "radial-gradient(circle at top left, #0f165c, #097a82)",

                  // background:
                  //   "linear-gradient(to right, red 10%, purple 45%, blue 85%)",
                  left: 0,
                  top: 0,
                  paddingLeft: 10,
                }}
              ></div>
            </Col>
          </Row>
          {hasilstate.payloadSoal.map((x) => {
            return (
              <Row
                key={x._id}
                justify="space-around"
                gutter={[20, 10]}
                style={{
                  background: "white",
                  borderRadius: 20,
                  border: "4px solid #0e7178",
                  marginBottom: 10,
                  // height: "100%",
                }}
              >
                <Col span={24}>
                  <div className="Pagename">Hasil</div>
                </Col>
                {x.soal_per_page.map((e, index) => {
                  return (
                    <Col
                      key={index}
                      span={4}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        // backgroundColor: "red",
                        minHeight: 200,
                      }}
                    >
                      <div style={{ marginRight: 10 }}>
                        {e.map((x, idx) => {
                          return (
                            <div key={idx + index}>
                              <div style={{ marginBottom: 20, marginTop: 20 }}>
                                {idx === 0 && (
                                  <Typography.Title level={5}>
                                    {x.angka1}
                                  </Typography.Title>
                                )}
                              </div>
                              <div style={{ marginBottom: 20, marginTop: 20 }}>
                                <Typography.Title level={5}>
                                  {x.angka2}
                                </Typography.Title>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {e.map((x, idx) => {
                          return (
                            <div
                              key={idx + index}
                              style={{ marginBottom: 20, marginTop: 20 }}
                            >
                              <Typography.Title
                                level={5}
                                style={{
                                  color:
                                    hasilstate.payloadJawaban.filter(
                                      (id) => id.soal_id == x._id
                                    ).length != 0 &&
                                    hasilstate.payloadJawaban.filter(
                                      (id) => id.soal_id == x._id
                                    )[0].hasil == "BENAR"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {hasilstate.payloadJawaban.filter(
                                  (id) => id.soal_id == x._id
                                ).length != 0 &&
                                hasilstate.payloadJawaban.filter(
                                  (id) => id.soal_id == x._id
                                )[0].jawaban_psikotes.jawaban !== null
                                  ? hasilstate.payloadJawaban
                                      .filter((id) => id.soal_id == x._id)[0]
                                      .jawaban_psikotes.jawaban.toString()
                                  : "-"}
                              </Typography.Title>
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  );
                })}

                <Col span={24}></Col>
              </Row>
            );
          })}
        </>
      )}
    </div>
  );
};
export default Hasil;
