import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";

import "./logikatest.css";
import { useDispatch, useSelector } from "react-redux";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";
import { Col, Radio, Row, Space, Spin } from "antd";

const Hasil = () => {
  const hasilstate = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  useEffect(() => {
    dispatch(
      HasilActions.soalRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
      })
    );
    dispatch(
      HasilActions.jawabanRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
        limit: 10000,
      })
    );
    dispatch(
      HasilActions.getHasilRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);
  //console.log(hasilstate.payloadJawaban);
  return (
    <>
      <Row style={{ padding: 20 }}>
        <Col
          span={24}
          style={{
            height: 50,
            backgroundColor: "black",
            marginBottom: 20,
            borderRadius: 20,
            border: "4px solid #0e7178",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 10,
              top: 0,
              bottom: 0,
              zIndex: 999999,
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h5 style={{ color: "white" }}>
              Score : {hasilstate.payloadHasil && hasilstate.payloadHasil.nilai}
              %
            </h5>
          </div>

          <div
            style={{
              display: "flex",
              position: "absolute",
              alignItems: "center",
              width: `${
                hasilstate.payloadHasil && hasilstate.payloadHasil.nilai
              }%`,
              height: "100%",
              background:
                "radial-gradient(circle at top left, #0f165c, #097a82)",
              // background:
              //   "linear-gradient(to right, red 10%, purple 45%, blue 85%)",
              left: 0,
              top: 0,
              paddingLeft: 10,
            }}
          ></div>
        </Col>
      </Row>
      {hasilstate.fetching ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        hasilstate.payloadSoal.map((e) => {
          return (
            <div className="ltcontent">
              <div className="ltcard">
                <div className="divsoal">
                  <div className="ltsoal">
                    <p>
                      {/* {soalState.payloadSoal.page}. */}
                      {e.soal_pertanyaan}
                    </p>
                  </div>
                </div>
                <div className="divpg">
                  {e.skolastic_type == "C" ? (
                    <div>
                      <p>
                        Jawaban :{" "}
                        {(hasilstate.payloadJawaban &&
                          hasilstate.payloadJawaban.filter(
                            (x) => x.msoal_id == e._id
                          ).length !== 0 &&
                          hasilstate.payloadJawaban.filter(
                            (x) => x.msoal_id == e._id
                          )[0].jawaban_pilihan_ganda.jawaban) ||
                          "-"}
                      </p>
                      {hasilstate.payloadJawaban &&
                        hasilstate.payloadJawaban.filter(
                          (x) => x.msoal_id == e._id
                        ).length !== 0 &&
                        hasilstate.payloadJawaban.filter(
                          (x) => x.msoal_id == e._id
                        )[0].hasil == "SALAH" && (
                          <p>
                            Jawaban benar :{" "}
                            {
                              hasilstate.payloadJawaban.filter(
                                (x) => x.msoal_id == e._id
                              )[0].jawaban_pilihan_ganda.jawaban_benar
                            }
                          </p>
                        )}
                    </div>
                  ) : (
                    <Radio.Group
                      // onChange={(val) => setjawaban(val.target.value)}
                      value={
                        hasilstate.payloadJawaban &&
                        hasilstate.payloadJawaban.filter(
                          (x) => x.msoal_id == e._id
                        ).length !== 0 &&
                        hasilstate.payloadJawaban.filter(
                          (x) => x.msoal_id == e._id
                        )[0].jawaban_pilihan_ganda.jawaban
                      }
                    >
                      <Space direction="vertical">
                        {e.pilihan_ganda.map((x) => {
                          const truejawaban =
                            hasilstate.payloadJawaban &&
                            hasilstate.payloadJawaban.filter(
                              (x) => x.msoal_id == e._id
                            ).length !== 0 &&
                            hasilstate.payloadJawaban.filter(
                              (x) => x.msoal_id == e._id
                            )[0].hasil == "SALAH" &&
                            hasilstate.payloadJawaban.filter(
                              (x) => x.msoal_id == e._id
                            )[0].jawaban_pilihan_ganda.jawaban_benar == x.kode;
                          return (
                            <Radio
                              style={{ color: "white", fontSize: 22 }}
                              value={x.kode}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div>{x.text}</div>
                                {truejawaban && (
                                  <div
                                    style={{
                                      width: 10,
                                      height: 10,
                                      borderRadius: 5,
                                      background: "#00ff94",
                                      marginLeft: 10,
                                      marginTop: 5,
                                    }}
                                  ></div>
                                )}
                              </div>
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  )}
                  <div className="skolastic-header">
                    {hasilstate.payloadJawaban &&
                    hasilstate.payloadJawaban.filter((i) => i.msoal_id == e._id)
                      .length !== 0 &&
                    hasilstate.payloadJawaban.filter(
                      (i) => i.msoal_id == e._id
                    )[0].hasil == "BENAR" ? (
                      <Card className="cardhasil1">BENAR</Card>
                    ) : (
                      <Card className="cardhasil2">SALAH</Card>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default Hasil;
