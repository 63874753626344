import React from "react";
import { Form, Input, Select } from "antd";
import { Link, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
// import {
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGithubSignIn,
//   userGoogleSignIn,
//   userSignUp,
//   userTwitterSignIn,
// } from "../appRedux/actions";

// import AuthActions from "../appRedux/reducers/AuthRedux";

// import IntlMessages from "util/IntlMessages";
// import { message } from "antd/lib/index";
// import CircularProgress from "../components/CircularProgress";
// import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
// import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
// import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
// import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import RegisterAction from "../appRedux/reducers/RegisterRedux";
import styles from "./signup.module.css";

const { Option } = Select;

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const history = useHistory();
  const {
    // loader, alertMessage, showMessage, authUser
  } = useSelector(({ auth }) => auth);

  const state = useSelector((state) => state.register);

  //console.log(state);

  // const history = useHistory();
  // useEffect(() => {
  //   if (showMessage) {
  //     setTimeout(() => {
  //       dispatch(hideMessage());
  //     }, 100);
  //   }
  //   if (authUser !== null) {
  //     history.push("/");
  //   }
  // });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    const data = {
      data: values,
      type: "siswa",
      history: history,
    };
    // dispatch(showAuthLoader());
    // dispatch(AuthActions.userSignUp(values, history));
    // //console.log(history);
    dispatch(RegisterAction.registerSiswaRequest(data));
  };

  // const [email, setEmail] = useState(null);

  return (
    <div className={styles.container}>
      <Link to={"../"}>
        <img src="/assets/images/icons/back.png" alt="back button"></img>
      </Link>
      <div>REGISTER</div>
      <Form
        initialValues={{ remember: false }}
        name="formregister"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className={`${styles.form} gx-signin-form gx-form-row0`}
      >
        <FormItem
          rules={[
            { required: true, message: "Email tidak valid", type: "email" },
          ]}
          name="email"
        >
          <Input type="email" placeholder="Email" />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: "Masukkan password!",
            },
          ]}
        >
          <Input type="password" placeholder="Password" />
        </FormItem>
        <FormItem
          name="nama"
          rules={[{ required: true, message: "Masukkan nama anda!" }]}
        >
          <Input placeholder="Nama" />
        </FormItem>
        <FormItem
          name="usia"
          rules={[{ required: true, message: "Masukkan usia anda!" }]}
        >
          <Input type={"number"} placeholder="Usia" />
        </FormItem>
        <FormItem
          name="alamat_lengkap"
          rules={[{ required: true, message: "Masukkan alamat lengkap anda!" }]}
        >
          <Input placeholder="Alamat lengkap" />
        </FormItem>
        <FormItem
          name="berat_badan"
          rules={[{ required: true, message: "Masukkan berat-badan anda!" }]}
        >
          <Input type={"number"} placeholder="Berat badan" />
        </FormItem>

        <FormItem
          name="tinggi_badan"
          rules={[{ required: true, message: "Masukkan tinggi-badan anda!" }]}
        >
          <Input type={"number"} placeholder="Tinggi badan" />
        </FormItem>
        <FormItem
          name="keahlian"
          rules={[
            {
              required: true,
              message: "Masukkan kompetensi kealian!",
            },
          ]}
        >
          {/* <Input placeholder="Kompetensi kealian" /> */}
          {/* <Select
            // className="select option"
            bordered={false}
            // showSearch
            placeholder="Kompetensi Keahlian"
            optionFilterProp="children"
          >
            <Option value="Akuntansi">Akuntansi</Option>
            <Option value="DKV">DKV</Option>
            <Option value="RPL">RPL</Option>
            <Option value="TKJ">TKJ</Option>
            <Option value="Tata Busana">Tata Busana</Option>
            <Option value="Teknik Pemesinan">Teknik Pemesinan</Option>
            <Option value="Teknik Pengelasan">Teknik Pengelasan</Option>
            <Option value="Tek.Kendarsan Ringan (Otomotif)">
              Tek.Kendarsan Ringan (Otomotif)
            </Option>
          </Select> */}

          <Select
            className={styles["signup-keahlian"]}
            defaultValue="default"
            // onChange={handleChange}
            bordered={false}
          >
            <Option value="default" disabled>
              Kompetensi Keahlian
            </Option>
            <Option value="Akuntansi">Akuntansi</Option>
            <Option value="OTKP">OTKP</Option>
            <Option value="DKV">DKV</Option>
            <Option value="RPL">RPL</Option>
            <Option value="TKJ">TKJ</Option>
            <Option value="Multimedia/Desain Grafis">
              Multimedia/Desain Grafis
            </Option>
            <Option value="Tata Busana">Tata Busana</Option>
            <Option value="Teknik Pemesinan">Teknik Pemesinan</Option>
            <Option value="Teknik Pengelasan">Teknik Pengelasan</Option>
            <Option value="Teknik Otomotif">Teknik Otomotif</Option>
            <Option value="Tek.Kendarsan Ringan (Otomotif)">
              Tek.Kendarsan Ringan (Otomotif)
            </Option>
            <Option value="Pariwisata">Pariwisata</Option>
            <Option value="Tata Boga">Tata Boga</Option>
            <Option value="Farmasi">Farmasi</Option>
            <Option value="Keperawatan">Keperawatan</Option>
            <Option value="Perhotelan">Perhotelan</Option>
          </Select>
        </FormItem>
        <FormItem className="text-center">
          {state.fetching ? (
            <button htmlType="submit" disabled>
              Loading...
            </button>
          ) : (
            <button htmlType="submit">DAFTAR</button>
          )}
          {/* <span>
            Sudah punya akun? <Link to="/signin">Masuk</Link>
          </span> */}
        </FormItem>
      </Form>
    </div>
  );
};

export default SignUp;
