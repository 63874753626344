import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import cyrb53 from "../lib/hasString";

const admin = cyrb53("admin");
const siswa = cyrb53("siswa");
const user = JSON.parse(localStorage.getItem("user_credent"));

// //console.log(user.role_name === siswa);

// //console.log(user.role_name);

export const SiswaRoute = ({ path, component }) => {
  const localstate = useSelector((state) => state.local).user_credent;
  // console.log(localstate);
  if (localstate.role_name === siswa) {
    return <Route path={path} component={component} />;
  } else if (localstate.role_name === admin) {
    return <Redirect to={"/soal"} />;
  } else {
    return <Redirect to="/signin" />;
  }
};

export const AdminRoute = ({ path, component }) => {
  const localstate = useSelector((state) => state.local).user_credent;
  // console.log(localstate);
  if (localstate.role_name === admin) {
    return <Route path={path} component={component} />;
  } else if (localstate.role_name === siswa) {
    return <Redirect to={"dashboard"} />;
  } else {
    return <Redirect to="/signin" />;
  }
};

// export const InitialRoute = ({ path, dashboardAdmin, dashboardSiswa }) => {
//   if (user.role_name === admin) {
//     return <Route exact path={path} component={dashboardAdmin} />;
//   } else if (user.role_name === siswa) {
//     return <Route exact path={path} component={dashboardSiswa} />;
//   } else {
//     return <Redirect to="/signin" />;
//   }
// };
