import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
// import Checkbox from "../../../components/Checkbox/Checkbox";
import Countdown from "react-countdown";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SoalActions from "../../../appRedux/reducers/SoalRedux";
import Container from "../../../components/TestingApp/Container";
import { Spin } from "antd";
import moment from "moment";

const TreeTest = () => {
  const dispatch = useDispatch();
  const soalState = useSelector((state) => state.soal);
  const localstate = useSelector((state) => state.local);
  const user = JSON.parse(localStorage.getItem("user_credent"));
  const history = useHistory();

  const [timeRun, setTimeRun] = useState(0);

  useEffect(() => {
    if (localstate.jenis_soal_id && localstate.event_id) {
      dispatch(
        SoalActions.getPageRequest({
          m_user_id: user.m_user_id,
          event_id: localstate.event_id,
          jenis_soal_id: localstate.jenis_soal_id,
          page: 1,
          history: history,
          jenis_soal: "treetest",
        })
      );
    } else {
      history.replace("/");
    }
  }, [localstate]);

  useEffect(() => {
    if (soalState.payloadSoal) {
      dispatch(
        SoalActions.getTimeRequest({
          m_user_id: user.m_user_id,
          event_id: localstate.event_id,
          jenis_soal_id: localstate.jenis_soal_id,
          waktu: soalState.payloadSoal.waktu,
          msoal_id: soalState.payloadSoal._id,
        })
      );
    }
  }, [soalState.payloadSoal]);

  const Selesai = () => <div>Waktu Habis!</div>;

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <Selesai />;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const [images, setImages] = useState(null); // nilai awal images false
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    if (imageList.length == 0) {
      setImages(null);
    } else {
      setImages(imageList[0].data_url);
    }
  };

  const next = () => {
    dispatch(SoalActions.getTimeSuccess(null));
    dispatch(SoalActions.getPsikotesSuccess(null));
    dispatch(SoalActions.nextPageSuccess(null));
    const data = {
      m_user_id: user.m_user_id,
      msoal_id: soalState.payloadSoal._id,
      event_id: localstate.event_id,
      jawaban_gambar: images,
      datapage_id: soalState.payloaddatapage._id,
      page: soalState.payloaddatapage.page,
      totalpage: soalState.payloaddatapage.totalpage,
      jenis_soal_id: localstate.jenis_soal_id,
      history: history,
      jenis_soal: "treetest",
      waktu: timeRun,
      // (
      //   (new Date().getTime() -
      //     new Date(soalState.payloadTime.createdAt).getTime()) /
      //   1000
      // ).toFixed(0)
    };
    dispatch(SoalActions.saveJawabanRequest(data));
    // //console.log(data);
  };

  return (
    <Container title="tree tes">
      {soalState.fetching ? (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <Spin size="large" />
        </div>
      ) : (
        <TreeTesBox>
          <Content>
            {/* <Title>Tree Test</Title> */}
            <Wrapper>
              {/* {images ? (
                <Balance></Balance>
              ) : ( */}
              <TimeTree>
                {soalState.payloadTime && (
                  <Countdown
                    onTick={(time) =>
                      setTimeRun(
                        soalState.payloadSoal.waktu - time.total / 1000
                      )
                    }
                    onMount={(value) => {
                      //console.log("completed", value.completed);
                      if (value.completed) {
                        next();
                      }
                    }}
                    onComplete={next}
                    date={soalState.payloadTime.skip_in}
                    renderer={renderer}
                  >
                    <Selesai />
                  </Countdown>
                )}
              </TimeTree>
              {/* )} */}

              <Content2>
                <Kiri>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      isDragging,
                      dragProps,
                      onImageRemoveAll,
                    }) => (
                      <>
                        <BoxUpload>
                          {images ? (
                            <div
                              className="image-item"
                              style={{
                                justifyContent2: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <img
                                src={images}
                                alt=""
                                style={{
                                  objectFit: "contain",
                                  height: 240,
                                  width: 240,
                                }}
                              />
                            </div>
                          ) : (
                            <UploadButton
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Upload disini
                            </UploadButton>
                          )}
                          {/* &nbsp;
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              className="image-item"
                              style={{
                                justifyContent2: "center",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <img
                                src={image["data_url"]}
                                alt=""
                                style={{
                                  objectFit: "contain",
                                  height: 240,
                                  width: 240,
                                }}
                              />
                            </div>
                          ))} */}
                        </BoxUpload>
                        {images && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              marginTop: 10,
                            }}
                          >
                            <UploadButton
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={() => {
                                onImageRemoveAll();
                                onImageUpload();
                              }}
                              {...dragProps}
                            >
                              Upload ulang
                            </UploadButton>
                          </div>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </Kiri>
              </Content2>
            </Wrapper>
            <DescriptionTree>
              {soalState.payloadSoal && soalState.payloadSoal.soal_pertanyaan}
            </DescriptionTree>
            <NextCol>
              {images && (
                <ButtonSubmit onClick={() => next()}>SUBMIT</ButtonSubmit>
              )}
            </NextCol>
          </Content>
        </TreeTesBox>
      )}
    </Container>
  );
};

export default TreeTest;

export const ContainerTree = styled.div`
  /* font-family: "Poppins"; */
  font-weight: bold;
  /* padding: 2rem; */
  display: grid;
  justify-content: center;
  color: white;
  /* transform: scale(0.8); */

  @media screen and (max-width: 1200px) {
    width: max-content;
    justify-content: center;
    display: grid;
  }
  @media screen and (max-width: 868px) {
    width: max-content;
  }
`;

export const TreeTesBox = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  /* border: 4px solid #0e7178; */
  width: 100%;
  height: 100%;
  /* margin-top: 2rem; */
  border-radius: 10px;
  background: white;
  /* transform: scale(1.5); */

  @media (max-width: 576px) {
    width: 100%;
    /* transform: scale(1.2); */
  }
`;

export const Content = styled.div`
  justify-items: center;
  display: grid;
  gap: 2rem;
`;

export const Title = styled.div`
  border-radius: 0px 0px 20px 20px;
  /* border-radius: 20px; */
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: grid;
  color: white;
  height: 50px;
  width: 500px;
  position: relative;
  text-transform: uppercase;
`;
const DescriptionTree = styled.p`
  /* font-size: 24px; */
  width: 700px;
  text-align: center;
  /* display: flex; */
  font-weight: 700;
  font-size: 20px;
  color: #5b5b5b;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const ButtonSubmit = styled.button`
  /* font-weight: 600; */
  /* font-family: "Poppins"; */
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  /* background: linear-gradient(90deg, #00ffa3 -4.7%, #003b3f 93.47%); */
  border: none;
  /* border-radius: 15px; */
  /* min-width: 110px;
  height: 35px; */
  min-width: 181px;
  height: 50px;
  color: white;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;

  font-weight: 700;
  font-size: 20px;

  background: linear-gradient(90deg, #00ffa3 -4.7%, #003b3f 93.47%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 31px;

  :focus {
    outline: none;
  }

  :hover {
    filter: brightness(70%);
  }
`;

const NextCol = styled.div`
  display: flex;
  justify-content: end;
  margin-left: 50rem;
  margin-right: 2rem;

  @media screen and (max-width: 868px) {
    margin-left: auto;
    grid-template-columns: 1fr;
  }
`;
const Wrapper = styled.div``;
const Content2 = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const BoxUpload = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  background: rgba(217, 217, 217, 0.74);
  box-sizing: border-box;
  border: 4px solid #0e7178;
  width: 250px;
  height: 250px;
  border-radius: 15px;
`;

const UploadButton = styled.button`
  font-weight: 600;
  /* font-family: "Poppins"; */
  justify-content: center;
  display: flex;
  /* background: linear-gradient(180deg, #00656c 0%, #00d0de 100%); */
  background: linear-gradient(90deg, #02deed -4.7%, #006067 93.47%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 31px;
  /* border: 2px solid #d9d9d9; */
  border: none;
  /* border-radius: 15px; */
  min-width: 110px;
  /* height: 35px; */
  padding: 5px 16px;
  /* letter-spacing: 0.5px; */
  color: white;

  :hover {
    filter: brightness(70%);
    transform: scale(1);
    cursor: pointer;
  }
`;

const CardFlow = styled.div`
  font-weight: bold;
  justify-content: center;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  width: 110px;
  height: 35px;
`;

const Kiri = styled.div``;
const Kanan = styled.div`
  margin: 2rem;
  display: grid;
  gap: 5px;
  align-items: center;
`;

const RowFlow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 3px;
`;
const Ceklis = styled.div`
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  border: 2px solid #d9d9d9;
  border-radius: 34px;
`;
const TimeTree = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: black;
  justify-content: center;
  display: flex;
`;

const Balance = styled.div`
  color: black;
  justify-content: center;
  display: grid;
  align-items: center;
  font-size: 23px;
`;
