const jawaban_siswa = [
  [
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
  ],
  [
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
  ],
  [
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
  ],
  [
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
  ],
  [
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
    {
      _id: null,
      jawaban: null,
    },
  ],
];

export default jawaban_siswa;
