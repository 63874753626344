import styled from "styled-components";
import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
// import Checkbox from "../../../components/Checkbox/Checkbox";
// import Countdown from "react-countdown";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import SoalActions from "../../../appRedux/reducers/SoalRedux";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";
import Container from "../../../../components/TestingApp/Container";
import { Checkbox, Spin, Row, Col } from "antd";

const TreeTest = () => {
  const hasilstate = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  useEffect(() => {
    dispatch(
      HasilActions.soalRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
      })
    );
    dispatch(
      HasilActions.jawabanRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
        limit: 10000,
      })
    );
    dispatch(
      HasilActions.getHasilRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);

  const [images, setImages] = useState(false); // nilai awal images false
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList); // mengubah nilai images ke true
    // //console.log(imageList);
  };
  //console.log(hasilstate.payloadJawaban);
  return (
    <>
      {hasilstate.payloadHasil && hasilstate.payloadHasil.detail && (
        <Row
          style={{
            marginBottom: 60,
            backgroundColor: "transparent",
            padding: 25,
          }}
        >
          <Col
            span={24}
            style={{
              height: 50,
              backgroundColor: "black",
              marginBottom: 20,
              borderRadius: 20,
              border: "4px solid #0e7178",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 10,
                top: 0,
                bottom: 0,
                zIndex: 999999,
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h5 style={{ color: "white" }}>
                Score :{" "}
                {hasilstate.payloadHasil && hasilstate.payloadHasil.nilai}%
              </h5>
            </div>

            <div
              style={{
                display: "flex",
                position: "absolute",
                alignItems: "center",
                width: `${
                  hasilstate.payloadHasil && hasilstate.payloadHasil.nilai
                }%`,
                height: "100%",
                background:
                  "radial-gradient(circle at top left, #0f165c, #097a82)",

                // background:
                //   "linear-gradient(to right, red 10%, purple 45%, blue 85%)",
                left: 0,
                top: 0,
                paddingLeft: 10,
              }}
            ></div>
          </Col>
        </Row>
      )}
      <Container title="tree tes">
        {hasilstate.fetching ? (
          <div style={{ textAlign: "center", marginTop: "20%" }}>
            <Spin size="large" />
          </div>
        ) : (
          <TreeTesBox>
            <Content>
              {/* <Title>Tree Test</Title> */}
              <Wrapper>
                <Content2>
                  <Kiri>
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        isDragging,
                        dragProps,
                        onImageRemoveAll,
                      }) => (
                        <>
                          <BoxUpload>
                            {hasilstate.payloadJawaban.map((image, index) => (
                              <div
                                key={index}
                                className="image-item"
                                style={{
                                  justifyContent2: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <img
                                  src={image.jawaban_gambar}
                                  alt=""
                                  style={{
                                    objectFit: "contain",
                                    height: 240,
                                    width: 240,
                                  }}
                                />
                              </div>
                            ))}
                          </BoxUpload>
                          {images && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                marginTop: 10,
                              }}
                            >
                              <UploadButton
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={() => {
                                  onImageRemoveAll();
                                  onImageUpload();
                                }}
                                {...dragProps}
                              >
                                Upload ulang
                              </UploadButton>
                            </div>
                          )}
                        </>
                      )}
                    </ImageUploading>
                  </Kiri>
                  {hasilstate.payloadHasil &&
                    hasilstate.payloadHasil.detail && (
                      <Kanan>
                        <RowFlow>
                          <CardFlow>Mahkota</CardFlow>
                          <Ceklis>
                            <Checkbox
                              checked={hasilstate.payloadHasil.detail.mahkota}
                            />
                          </Ceklis>
                        </RowFlow>
                        <RowFlow>
                          <CardFlow>Batang Pohon</CardFlow>
                          <Ceklis>
                            <Checkbox
                              checked={
                                hasilstate.payloadHasil.detail.batang_pohon
                              }
                            />
                          </Ceklis>
                        </RowFlow>
                        <RowFlow>
                          <CardFlow>Dahan</CardFlow>
                          <Ceklis>
                            <Checkbox
                              checked={hasilstate.payloadHasil.detail.dahan}
                            />
                          </Ceklis>
                        </RowFlow>
                        <RowFlow>
                          <CardFlow>Pangkal Pohon</CardFlow>
                          <Ceklis>
                            <Checkbox
                              checked={
                                hasilstate.payloadHasil.detail.pangkal_pohon
                              }
                            />
                          </Ceklis>
                        </RowFlow>
                        <RowFlow>
                          <CardFlow>Akar</CardFlow>
                          <Ceklis>
                            <Checkbox
                              checked={hasilstate.payloadHasil.detail.akar}
                            />
                          </Ceklis>
                        </RowFlow>
                      </Kanan>
                    )}
                </Content2>
              </Wrapper>
              <DescriptionTree>
                {(!hasilstate.payloadHasil ||
                  !hasilstate.payloadHasil.detail) && (
                  // <p>{`Skor anda adalah ${hasilstate.payloadHasil.nilai}%`}</p>

                  <p>Hasil jawaban anda sedang diperiksa</p>
                )}
              </DescriptionTree>
            </Content>
          </TreeTesBox>
        )}
      </Container>
    </>
  );
};

export default TreeTest;

export const ContainerTree = styled.div`
  /* font-family: "Poppins"; */
  font-weight: bold;
  /* padding: 2rem; */
  display: grid;
  justify-content: center;
  color: white;
  /* transform: scale(0.8); */

  @media screen and (max-width: 1200px) {
    width: max-content;
    justify-content: center;
    display: grid;
  }
  @media screen and (max-width: 868px) {
    width: max-content;
  }
`;

export const TreeTesBox = styled.div`
  margin-top: 20px;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  /* border: 4px solid #0e7178; */
  width: 100%;
  height: 100%;
  /* margin-top: 2rem; */
  border-radius: 10px;
  background: white;
  /* transform: scale(1.5); */

  @media screen and (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    display: flex;
    /* transform: scale(1.2); */
    margin-left: auto;
  }
  @media screen and (max-width: 868px) {
    width: 100%;
    /* transform: scale(1.2); */
  }
`;

export const Content = styled.div`
  justify-items: center;
  display: grid;
  gap: 2rem;
`;

export const Title = styled.div`
  border-radius: 0px 0px 20px 20px;
  /* border-radius: 20px; */
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: grid;
  color: white;
  height: 50px;
  width: 500px;
  position: relative;
  text-transform: uppercase;
`;
const DescriptionTree = styled.p`
  font-size: 24px;
  width: 100%;
  text-align: center;
  display: flex;
  color: #5b5b5b;
`;

export const ButtonSubmit = styled.button`
  font-weight: bold;
  /* font-family: "Poppins"; */
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  background: linear-gradient(90deg, #00ffa3 -4.7%, #003b3f 93.47%);
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  width: 110px;
  height: 35px;
  color: white;
  text-transform: capitalize;
  letter-spacing: 1px;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :hover {
    filter: brightness(70%);
  }
`;

const NextCol = styled.div`
  display: flex;
  justify-content: end;
  margin-left: 50rem;
  margin-right: 2rem;

  @media screen and (max-width: 868px) {
    margin-left: auto;
    grid-template-columns: 1fr;
  }
`;
const Wrapper = styled.div``;
const Content2 = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const BoxUpload = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  background: rgba(217, 217, 217, 0.74);
  box-sizing: border-box;
  border: 4px solid #0e7178;
  width: 250px;
  height: 250px;
  border-radius: 15px;
`;

const UploadButton = styled.button`
  font-weight: bold;
  /* font-family: "Poppins"; */
  justify-content: center;
  display: flex;
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  width: 110px;
  height: 35px;
  /* letter-spacing: 0.5px; */
  color: white;

  :hover {
    filter: brightness(70%);
    transform: scale(1);
    cursor: pointer;
  }
`;

const CardFlow = styled.div`
  font-weight: bold;
  justify-content: center;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  width: 110px;
  height: 35px;
`;

const Kiri = styled.div``;
const Kanan = styled.div`
  margin: 2rem;
  display: grid;
  gap: 5px;
  align-items: center;
`;

const RowFlow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 3px;
`;
const Ceklis = styled.div`
  background: linear-gradient(180deg, #00656c 0%, #00d0de 100%);
  border: 2px solid #d9d9d9;
  border-radius: 34px;
`;
const TimeTree = styled.div`
  font-size: 30px;
  color: black;
  justify-content: center;
  display: flex;
`;

const Balance = styled.div`
  color: black;
  justify-content: center;
  display: grid;
  align-items: center;
  font-size: 23px;
`;
