import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AuthActions from "../../appRedux/reducers/AuthRedux";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ListPesertaEvent from "../PrivateContainer/Hasil/ListPeserta";
import detailPesertaEvent from "../PrivateContainer/Hasil/DetailPeserta";

import HasilPsikotes from "../PrivateContainer/HasilSoal/Psikotes";
import HasilLogikates from "../PrivateContainer/HasilSoal/LogikaTes/hasil";
import HasilTiutes from "../PrivateContainer/HasilSoal/TiuTes";
import HasilSkolastictes from "../PrivateContainer/HasilSoal/SkolasticTes/Hasil2";
import HasilWarttegtes from "../PrivateContainer/HasilSoal/WarttegTest/hasil2";
import HasilTreetes from "../PrivateContainer/HasilSoal/TreeTes/hasil";
import MinatKarirTes from "../PrivateContainer/HasilSoal/MinatKarirTes/hasil2"

import HasilPsikotesGrafik from "../PrivateContainer/HasilSoal/Psikotes/grafik";
import HasilLogikatesGrafik from "../PrivateContainer/HasilSoal/LogikaTes/chartlogika/chartlogika";
import HasilTiutesGrafik from "../PrivateContainer/HasilSoal/TiuTes/grafik";
import HasilSkolastictesGrafik from "../PrivateContainer/HasilSoal/SkolasticTes/grafik";
import HasilWarttegtesGrafik from "../PrivateContainer/HasilSoal/WarttegTest/Grafik";
import HasilTreetesGrafik from "../PrivateContainer/HasilSoal/TreeTes/grafik";
import HasilMinatkarirteGrafik from "../PrivateContainer/HasilSoal/MinatKarirTes/Grafik"

import { setInitUrl } from "../../appRedux/actions";

import LoadingOverlay from "react-loading-overlay-ts";

import { HashRouter } from "react-router-dom";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";

import ModalInfo from "../../components/ModalInfo";
import bcrypt from "bcryptjs";
import cyrb53 from "../../lib/hasString";
import Hasil from "../PrivateContainer/HasilSoal/MinatKarirTes/hasil2";
// import * as config from "../../util/config";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
  const loadingoverlay = useSelector(({ loadingoverlay }) => loadingoverlay);

  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const userstate = useSelector((state) => state.local);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
    }
  }, [themeType]);

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
  });
  const user = JSON.parse(localStorage.getItem("user_credent"));
  const admin = cyrb53("admin");
  const siswa = cyrb53("siswa");

  // //console.log(userstate.user_credent);
  // //console.log(authUser);

  const authuser = userstate.user_credent;

  useEffect(() => {
    if (location.pathname === "/") {
      if (authUser === null) {
        history.push("/signin");
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        if (user.role_name === admin) {
          history.push("/soal");
        } else if (user.role_name === siswa) {
          history.push("/dashboard");
        }
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  const localstate = useSelector((state) => state.local);

  // //console.log("redux credent", localstate.user_credent);
  // //console.log("local credent", user);
  useEffect(() => {
    if (JSON.stringify(user) !== JSON.stringify(localstate.user_credent)) {
      dispatch(AuthActions.userSignOut());
    }
  }, [user]);

  // //console.log(cyrb53("siswa"), cyrb53("admin"));
  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/h" component={ListPesertaEvent} />
          <Route exact path="/dp" component={detailPesertaEvent} />
          <Route
            path={`/hp/Psikotes/:eventId/:mUserId/:jenisSoalId`}
            component={HasilPsikotes}
          />
          <Route
            path={`/hp/PsikotesGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={HasilPsikotesGrafik}
          />
          <Route
            path={`/hp/LogikaTest/:eventId/:mUserId/:jenisSoalId`}
            component={HasilLogikates}
          />
          <Route
            path={`/hp/LogikaTestGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={HasilLogikatesGrafik}
          />
          <Route
            path={`/hp/TiuTest/:eventId/:mUserId/:jenisSoalId`}
            component={HasilTiutes}
          />
          <Route
            path={`/hp/TiuTestGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={HasilTiutesGrafik}
          />
          <Route
            path={`/hp/SkolasticTest/:eventId/:mUserId/:jenisSoalId`}
            component={HasilSkolastictes}
          />
          <Route
            path={`/hp/SkolasticTestGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={HasilSkolastictesGrafik}
          />
          <Route
            path={`/hp/TreeTest/:eventId/:mUserId/:jenisSoalId`}
            component={HasilTreetes}
          />
          <Route
            path={`/hp/TreeTestGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={HasilTreetesGrafik}
          />
          <Route
            path={`/hp/WarttegTest/:eventId/:mUserId/:jenisSoalId`}
            component={HasilWarttegtes}
          />
          <Route
            path={`/hp/WarttegTestGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={HasilWarttegtesGrafik}
          />
          <Route
            path={`/hp/MinatKarirTes/:eventId/:mUserId/:jenisSoalId`}
            component={MinatKarirTes}
          />
          <Route
            path={`/hp/MinatKarirTes/:eventId/:mUserId/:jenisSoalId`}
            component={HasilMinatkarirteGrafik}
          />
          {/* <Route
            path={`${match.url}hasilpeserta/PsikotesGrafik/:eventId/:mUserId/:jenisSoalId`}
            component={import("./Admin/Hasil/HasilSoal/Psikotes/grafik")}
          />
          
          
          
          
           */}

          {/* Route di dalam user LOGIN */}
          <LoadingOverlay
            active={loadingoverlay.show}
            spinner
            text={loadingoverlay.message ? loadingoverlay.message : undefined}
          >
            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              location={location}
              component={MainApp}
            />
          </LoadingOverlay>
        </Switch>
        <ModalInfo title="test" visible={false} />
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
