/* ***********************************************************
 * A short word on how to use this automagically generated file.
 * We're often asked in the ignite gitter channel how to connect
 * to a to a third party api, so we thought we'd demonstrate - but
 * you should know you can use sagas for other flow control too.
 *
 * Other points:
 *  - You'll need to add this saga to sagas/index.js
 *  - This template uses the api declared in sagas/index.js, so
 *    you'll need to define a constant in that file.
 *************************************************************/

import { call, put } from "redux-saga/effects";
import { Col, message, Modal, Row } from "antd";
import EventAction from "../reducers/EventRedux";
import SoalAction from "../reducers/SoalRedux";
import { ExclamationCircleFilled } from "@ant-design/icons";
import _ from "lodash";
// import { TemplateSelectors } from '../Redux/TemplateRedux'

export function* eventSiswaRequest(api, action) {
  const { data } = action;
  //console.log(data);
  const history = data.history;
  const response = yield call(api.eventSiswaRequest, data.data);
  if (response.ok) {
    message.destroy();
    Modal.success({
      title: "success",
      mesage: "Berhasil mendaftar",
      onOk() {
        if (history) history.push("/dashboard");
      },
    });
    yield put(EventAction.eventSiswaSuccess());
  } else {
    message.destroy();
    yield put(EventAction.eventSiswaFailure());
  }
}

export function* eventRequest(api, action) {
  const { data } = action;
  const response = yield call(
    api.eventRequest,
    data
    // { limit: 1000 }
  );
  // success?
  if (response.ok) {
    yield put(EventAction.eventSuccess(response.data));
  } else {
    yield put(EventAction.eventFailure());
  }
}

export function* detailEventRequest(api, action) {
  const { data } = action;
  const history = data.history;
  const response = yield call(api.detailEventRequest, data.data);
  // success?
  if (response.ok) {
    if (response.data.data.sisa_waktu_selesai <= 0 && data.history) {
      yield put(EventAction.detailEventFailure());
      message.info("Event Sudah berakhir");
      history.goBack();
    }
    yield put(EventAction.detailEventSuccess(response.data.data));
  } else {
    yield put(EventAction.detailEventFailure());
  }
}

export function* eventTerdaftarRequest(api, action) {
  const { data } = action;
  console.log(data);
  const response = yield call(api.getdetaileventbyuserid, data);
  if (response.ok) {
    yield put(EventAction.eventTerdaftarSuccess(response.data));
  } else {
    yield put(EventAction.eventTerdaftarFailure());
  }
}

export function* eventAktifRequest(api, action) {
  const { data } = action;
  const response = yield call(api.eventAktif, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(EventAction.eventAktifSuccess(response.data.data));
  } else {
    yield put(EventAction.eventAktifFailure());
  }
}

export function* adminDetailEventRequest(api, action) {
  const { data } = action;
  const response = yield call(api.detailEvent, data);
  if (response.ok) {
    yield put(EventAction.adminDetailEventSuccess(response.data.data));
  } else {
    yield put(EventAction.adminDetailEventFailure());
  }
}

export function* addEventRequest(api, action) {
  const { data } = action;
  if (!data.logo) {
    message.error("Gambar logo tidak boleh kosong");
    yield put(EventAction.addEventFailure());
    return;
  }
  const response = yield call(api.createEvent, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(EventAction.addEventSuccess());
    message.success("Event berhasil dibuat");
  } else {
    yield put(EventAction.addEventFailure());
  }
}

export function* deleteEventRequest(api, action) {
  const { data } = action;
  // console.log(data);
  const response = yield call(api.deleteEvent, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(SoalAction.deleteAllSoalEventRequest({ event_id: data._id }));
    yield put(SoalAction.deletePageRequest({ event_id: data._id }));
    yield put(EventAction.eventRequest());
    yield put(EventAction.deleteEventSuccess());
    data.history.goBack();
    message.success("Event berhasil dihapus");
  } else {
    yield put(EventAction.deleteEventFailure());
  }
}

export function* adminPesertaEventRequest(api, action) {
  const { data } = action;
  const response = yield call(api.adminPesertaEventRequest, data);
  if (response.ok) {
    // //console.log("peserta event", response.data.data);
    yield put(EventAction.adminPesertaEventSuccess(response.data.data));
  } else {
    yield put(EventAction.adminPesertaEventFailure());
  }
}

export function* kodeEventRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.getKodeEvent, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);

    yield put(EventAction.kodeEventSuccess(response.data));
  } else {
    yield put(EventAction.kodeEventFailure());
  }
}

export function* cetakKodeRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.cetakKode, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);

    yield put(EventAction.cetakKodeSuccess());
    yield put(
      EventAction.kodeEventRequest({
        isActivated: false,
        isPrinted: false,
        event_id: data.event_id,
        limit: 1000,
      })
    );
  } else {
    yield put(EventAction.cetakKodeFailure());
  }
}

export function* getPesertaRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.getPeserta, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);

    yield put(EventAction.getPesertaSuccess(response.data));
  } else {
    yield put(EventAction.getPesertaFailure());
  }
}

export function* getDetailPesertaRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.getDetailPeserta, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);

    yield put(EventAction.getDetailPesertaSuccess(response.data.data));
  } else {
    yield put(EventAction.getDetailPesertaFailure());
  }
}

export function* getSekolahRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.getsekolah, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);

    yield put(EventAction.getSekolahSuccess(response.data));
  } else {
    yield put(EventAction.getSekolahFailure());
  }
}
