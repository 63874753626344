import React, { useEffect } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { config } from "../../../../data/DataGrafikTreeTest";
import styled from "styled-components";
import Container from "../../../../components/TestingApp/Container";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";
import { useRouteMatch } from "react-router-dom";

const Grafik = () => {
  //   const history = useHistory();
  const hasilState = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();

  const datagrafik = hasilState.payloadGrafik.map((e) => ({
    label: `Page ${e.page}`,
    value: e.waktu,
  }));

  config.dataProvider = datagrafik;
  useEffect(() => {
    dispatch(
      HasilActions.hasilGrafikRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);

  console.log(hasilState.payloadGrafik);

  return (
    <Container padding={200} title="GRAFIK HASIL PSIKOTES" header={true}>
      {hasilState.fetching ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <AmCharts.React
          style={{ width: "100%", height: "500px", marginTop: "40px" }}
          options={config}
        />
      )}
    </Container>
  );
};

export default Grafik;

export const ContainerPsikotes = styled.div`
  /* font-family: "Poppins"; */
  font-weight: bold;
  width: 100% !important;
  display: grid;
  justify-content: center;
  align-items: start;
  color: white;

  @media screen and (max-width: 1200px) {
    width: max-content;
    justify-content: center;
    display: grid;
  }
  @media screen and (max-width: 868px) {
    width: max-content;
  }
`;
