import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  eventSiswaRequest: ["data"],
  eventSiswaSuccess: null,
  eventSiswaFailure: null,

  eventRequest: ["data"],
  eventSuccess: ["payload"],
  eventFailure: null,

  detailEventRequest: ["data"],
  detailEventSuccess: ["payload"],
  detailEventFailure: null,

  eventTerdaftarRequest: ["data"],
  eventTerdaftarSuccess: ["payload"],
  eventTerdaftarFailure: null,

  eventAktifRequest: ["data"],
  eventAktifSuccess: ["payload"],
  eventAktifFailure: null,

  adminDetailEventRequest: ["data"],
  adminDetailEventSuccess: ["payload"],
  adminDetailEventFailure: null,

  addEventRequest: ["data"],
  addEventSuccess: null,
  addEventFailure: null,

  deleteEventRequest: ["data"],
  deleteEventSuccess: null,
  deleteEventFailure: null,

  adminPesertaEventRequest: ["data"],
  adminPesertaEventSuccess: ["payload"],
  adminPesertaEventFailure: null,

  detailPesertaRequest: ["data"],
  detailPesertaSuccess: ["payload"],
  detailPesertaFailure: null,

  kodeEventRequest: ["data"],
  kodeEventSuccess: ["payload"],
  kodeEventFailure: null,

  cetakKodeRequest: ["data"],
  cetakKodeSuccess: null,
  cetakKodeFailure: null,

  getPesertaRequest: ["data"],
  getPesertaSuccess: ["payload"],
  getPesertaFailure: null,

  getDetailPesertaRequest: ["data"],
  getDetailPesertaSuccess: ["payload"],
  getDetailPesertaFailure: null,

  getSekolahRequest: ["data"],
  getSekolahSuccess: ["payload"],
  getSekolahFailure: null,
});

export const EventTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  fetching: null,
  payload: null,
  error: null,

  datadetail: null,
  fetchingdetail: null,
  payloaddetail: null,
  errordetail: null,

  dataEventAktif: null,
  eventAktif: null,

  dataCreateEvent: null,

  dataDeleteEvent: null,

  datapeserta: null,
  payloadpeserta: [],

  datadetailpeserta: null,
  payloaddetailpeserta: null,

  dataKodeEvent: null,
  payloadKodeEvent: null,

  dataCetakKode: null,

  datapesertaevent: null,
  payloadpesertaevent: null,

  datadetailpesertaevent: null,
  payloaddetailpesertaevent: null,

  dataSekolah: null,
  payloadSekolah: null,
});

/* ------------- Selectors ------------- */

export const EventSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const request = (state, { data }) =>
  state.merge({ fetching: true, data });

// successful api lookup
export const success = (state, action) => {
  const { payload } = action;
  return state.merge({ fetching: false, error: null, payload });
};

// Something went wrong somewhere.
export const failure = (state) => state.merge({ fetching: false, error: true });

// detail
export const detailrequest = (state, action) =>
  state.merge({ fetchingdetail: true, datadetail: action.data });

export const detailsuccess = (state, action) => {
  return state.merge({
    fetchingdetail: false,
    errordetail: null,
    payloaddetail: action.payload,
  });
};

export const detailfailure = (state) =>
  state.merge({ fetchingdetail: false, errordetail: true });

// detailpeserta
export const detailPesertarequest = (state, action) =>
  state.merge({ fetching: true, datadetailpeserta: action.data });

export const detailPesertasuccess = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloaddetailpeserta: action.payload,
  });
};

// export const detailPesertafailure = (state) =>
//   state.merge({ fetchingdetailpeserta: false, errordetailpeserta: true });
// =================================

export const eventSiswaSuccess = (state) => {
  return state.merge({ fetching: false, error: null });
};

export const requestEventAktif = (state, action) =>
  state.merge({ fetching: true, dataEventAktif: action.data });

// successful api lookup
export const successEventAktif = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    eventAktif: action.payload,
  });
};

export const requestAddEvent = (state, action) =>
  state.merge({ fetching: true, dataCreateEvent: action.data });

// successful api lookup
export const successAddEvent = (state) => {
  return state.merge({ fetching: false, error: null });
};

export const requestDeleteEvent = (state, action) =>
  state.merge({ fetching: true, dataDeleteEvent: action.data });

// successful api lookup
export const successDeleteEvent = (state) => {
  return state.merge({ fetching: false, error: null });
};

export const requestAdminPesertaEvent = (state, action) =>
  state.merge({ fetching: true, datapeserta: action.data });

// successful api lookup
export const successAdminPesertaEvent = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadpeserta: action.payload,
  });
};

export const requestKodeEvent = (state, action) =>
  state.merge({ fetching: true, dataKodeEvent: action.data });

// successful api lookup
export const successKodeEvent = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadKodeEvent: action.payload,
  });
};

export const requestCetakKode = (state, action) =>
  state.merge({ fetching: true, dataCetakKode: action.data });

// successful api lookup
export const successCetakKode = (state) => {
  return state.merge({
    fetching: false,
    error: null,
  });
};

export const requestPesertaEvent = (state, action) =>
  state.merge({ fetching: true, datapesertaevent: action.data });

// successful api lookup
export const successPesertaEvent = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadpesertaevent: action.payload,
  });
};

export const requestDetailPesertaEvent = (state, action) =>
  state.merge({ fetching: true, datadetailpesertaevent: action.data });

// successful api lookup
export const successDetailPesertaEvent = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloaddetailpesertaevent: action.payload,
  });
};

export const requestSekolah = (state, action) =>
  state.merge({ fetching: true, dataSekolah: action.data });

// successful api lookup
export const successSekolah = (state, action) => {
  // const { payload } = action;
  return state.merge({
    fetching: false,
    error: null,
    payloadSekolah: action.payload,
  });
};
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // event siswa
  [Types.EVENT_SISWA_REQUEST]: request,
  [Types.EVENT_SISWA_SUCCESS]: eventSiswaSuccess,
  [Types.EVENT_SISWA_FAILURE]: failure,

  //  event perusahaan
  [Types.EVENT_REQUEST]: request,
  [Types.EVENT_SUCCESS]: success,
  [Types.EVENT_FAILURE]: failure,

  //  detail event perusahaan
  [Types.DETAIL_EVENT_REQUEST]: detailrequest,
  [Types.DETAIL_EVENT_SUCCESS]: detailsuccess,
  [Types.DETAIL_EVENT_FAILURE]: detailfailure,

  [Types.EVENT_TERDAFTAR_REQUEST]: request,
  [Types.EVENT_TERDAFTAR_SUCCESS]: success,
  [Types.EVENT_TERDAFTAR_FAILURE]: failure,

  [Types.EVENT_AKTIF_REQUEST]: requestEventAktif,
  [Types.EVENT_AKTIF_SUCCESS]: successEventAktif,
  [Types.EVENT_AKTIF_FAILURE]: failure,

  [Types.ADMIN_DETAIL_EVENT_REQUEST]: detailrequest,
  [Types.ADMIN_DETAIL_EVENT_SUCCESS]: detailsuccess,
  [Types.ADMIN_DETAIL_EVENT_FAILURE]: detailfailure,

  [Types.ADD_EVENT_REQUEST]: requestAddEvent,
  [Types.ADD_EVENT_SUCCESS]: successAddEvent,
  [Types.ADD_EVENT_FAILURE]: failure,

  [Types.DELETE_EVENT_REQUEST]: requestDeleteEvent,
  [Types.DELETE_EVENT_SUCCESS]: successDeleteEvent,
  [Types.DELETE_EVENT_FAILURE]: failure,

  [Types.ADMIN_PESERTA_EVENT_REQUEST]: requestAdminPesertaEvent,
  [Types.ADMIN_PESERTA_EVENT_SUCCESS]: successAdminPesertaEvent,
  [Types.ADMIN_PESERTA_EVENT_FAILURE]: failure,

  //  detail peserta
  [Types.DETAIL_PESERTA_REQUEST]: detailPesertarequest,
  [Types.DETAIL_PESERTA_SUCCESS]: detailPesertasuccess,
  [Types.DETAIL_PESERTA_FAILURE]: failure,

  [Types.KODE_EVENT_REQUEST]: requestKodeEvent,
  [Types.KODE_EVENT_SUCCESS]: successKodeEvent,
  [Types.KODE_EVENT_FAILURE]: failure,

  [Types.CETAK_KODE_REQUEST]: requestCetakKode,
  [Types.CETAK_KODE_SUCCESS]: successCetakKode,
  [Types.CETAK_KODE_FAILURE]: failure,

  [Types.GET_PESERTA_REQUEST]: requestPesertaEvent,
  [Types.GET_PESERTA_SUCCESS]: successPesertaEvent,
  [Types.GET_PESERTA_FAILURE]: failure,

  [Types.GET_DETAIL_PESERTA_REQUEST]: requestDetailPesertaEvent,
  [Types.GET_DETAIL_PESERTA_SUCCESS]: successDetailPesertaEvent,
  [Types.GET_DETAIL_PESERTA_FAILURE]: failure,

  [Types.GET_SEKOLAH_REQUEST]: requestSekolah,
  [Types.GET_SEKOLAH_SUCCESS]: successSekolah,
  [Types.GET_SEKOLAH_FAILURE]: failure,
});
