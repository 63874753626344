import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
// import {userSignOut} from "../../appRedux/actions";
import AuthActions from "../../appRedux/reducers/AuthRedux";
import "./SidebarContent.css";

const UserProfile = ({ sidebarCollapsed, children }) => {
  const user = localStorage.getItem("user_credent")
    ? JSON.parse(localStorage.getItem("user_credent"))
    : null;
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li onClick={() => dispatch(AuthActions.userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="sidebar-user-profile">
      <div>
        <Avatar
          src={"https://via.placeholder.com/150"}
          className={"gx-size-40 gx-pointer "}
          alt=""
        />
      </div>

      {/* email dipindah ke SidebarContent */}
      <div
        className="sidebar-user-children gx-avatar-name ms-2"
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default UserProfile;
