import {
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import HasilActions from "../../../appRedux/reducers/HasilRedux";
import EventAction from "../../../appRedux/reducers/EventRedux";
import SoalActions from "../../../appRedux/reducers/SoalRedux";
import { decrypt } from "../../../lib/crypto";

const DetailPeserta = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const evenstate = useSelector((state) => state.event);
  const hasilState = useSelector((state) => state.hasil);
  const soalState = useSelector((state) => state.soal);
  // console.log(params);
  // console.log(searchParams.get());
  useEffect(() => {
    dispatch(
      EventAction.getDetailPesertaRequest({
        event_id:
          decrypt(searchParams.get("e")) == "undefined"
            ? null
            : decrypt(searchParams.get("e")),
        m_user_id:
          decrypt(searchParams.get("m")) == "undefined"
            ? null
            : decrypt(searchParams.get("m")),
      })
    );
    dispatch(
      EventAction.adminDetailEventRequest(
        decrypt(searchParams.get("e")) == "undefined"
          ? null
          : decrypt(searchParams.get("e"))
      )
    );
    dispatch(
      HasilActions.listHasilRequest({
        event_id:
          decrypt(searchParams.get("e")) == "undefined"
            ? null
            : decrypt(searchParams.get("e")),
        m_user_id:
          decrypt(searchParams.get("m")) == "undefined"
            ? null
            : decrypt(searchParams.get("m")),
      })
    );
    dispatch(SoalActions.jenisSoalRequest());
  }, []);
  const data = evenstate.payloaddetailpesertaevent;
  const columns = [
    {
      title: "Jenis soal",
      dataIndex: "jenis_soal_id",
      key: "jenis_soal_id",
      align: "center",
      render: (value) =>
        soalState.payload.filter((e) => e._id == value).length !== 0
          ? soalState.payload.filter((e) => e._id == value)[0].jenis_soal
          : null,
    },
    {
      title: "Nilai",
      dataIndex: "nilai",
      key: "nilai",
      align: "center",
      render: (value) => (value ? value : "0") + "%",
    },
    {
      title: "Aksi",
      key: "aksi",
      fixed: "right",
      align: "center",
      render: (value) => (
        <div>
          <Button
            style={{ margin: "auto", marginBottom: 5 }}
            size="small"
            onClick={() => {
              dispatch(HasilActions.jawabanSuccess([]));
              dispatch(HasilActions.hasilSuccess(null));
              soalState.payload.filter((e) => e._id == value.jenis_soal_id)
                .length !== 0
                ? history.push(
                    "/hp" +
                      soalState.payload.filter(
                        (e) => e._id == value.jenis_soal_id
                      )[0].url_web +
                      `/${value.event_id}` +
                      `/${value.m_user_id}` +
                      `/${value.jenis_soal_id}`
                  )
                : console.log("ok");
            }}
            type="primary"
          >
            Detail
          </Button>
          <Button
            style={{ margin: "auto" }}
            size="small"
            onClick={() => {
              dispatch(HasilActions.jawabanSuccess([]));
              dispatch(HasilActions.hasilSuccess(null));
              soalState.payload.filter((e) => e._id == value.jenis_soal_id)
                .length !== 0
                ? history.push(
                    "/hp" +
                      soalState.payload.filter(
                        (e) => e._id == value.jenis_soal_id
                      )[0].url_web +
                      "Grafik" +
                      `/${value.event_id}` +
                      `/${value.m_user_id}` +
                      `/${value.jenis_soal_id}`
                  )
                : console.log("ok");
            }}
            type="primary"
          >
            Grafik
          </Button>
        </div>
      ),
      width: 100,
    },
  ];
  console.log("rapor"+data);
  return (
    <div style={{ padding: 20 }}>
      <Typography.Title level={3}>
        {evenstate.payloaddetail && evenstate.payloaddetail.nama_perusahaan}
      </Typography.Title>
      {evenstate.fetching || hasilState.fetching || soalState.fetching ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row>
          <Col span={15}>
            <Card title="Data diri">
              <Descriptions layout="vertical">
                <Descriptions.Item label="Nama">
                  {data && data.nama}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {data && data.email}
                </Descriptions.Item>
                <Descriptions.Item label="Usia">
                  {data && data.usia} tahun
                </Descriptions.Item>
                <Descriptions.Item label="Berat badan">
                  {data && data.berat_badan} kg
                </Descriptions.Item>
                <Descriptions.Item label="Tinggi badan">
                  {data && data.tinggi_badan} cm
                </Descriptions.Item>
                <Descriptions.Item label="Kompetensi / Keahlian">
                  {data && data.keahlian}
                </Descriptions.Item>
                <Descriptions.Item label="Buta warna">
                  {data && data.buta_warna == "N" ? "Tidak" : "Iya"}
                </Descriptions.Item>
                <Descriptions.Item label="Bertato">
                  {data && data.bertato == "N" ? "Tidak" : "Iya"}
                </Descriptions.Item>
                <Descriptions.Item label="Alamat">
                  {data && data.alamat_lengkap}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Card title="Hasil ujian">
              <Table
                className="table-style"
                dataSource={hasilState.payloadListHasil}
                rowKey={(record) => record._id}
                columns={columns}
                pagination={false}
              />
            </Card>
          </Col>
          <Col span={9}>
            <Card title="Foto raport">
              {data && (
                <Image
                  alt="foto raport"
                  // width={200}
                  src={data.upl_raport}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DetailPeserta;
