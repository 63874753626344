import { call, put } from "redux-saga/effects";
import TemplateActions from "../reducers/TemplateRedux";

export function* templateRequest(api, action) {
  const { data } = action;
  const response = yield call(api.templateRequest, data);
  if (response.ok) {
    //console.log("template response.data", response.data);
    yield put(TemplateActions.templateSuccess(response.data));
  } else {
    yield put(TemplateActions.templateFailure());
  }
}
