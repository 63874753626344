import React from "react";
import "./index.css";

const Container = ({ title = "undefined", children = "undefined", header }) => {
  return (
    <div style={{ padding: 35, marginTop: 30 }} className="testing-container">
      <div className="testing-container-wrap">
        {header && (
          <div className="testing-container-title-wrap">
            <div>{title.toUpperCase()}</div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Container;
