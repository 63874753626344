import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  soalRequest: ["data"],
  soalSuccess: ["payload"],
  soalFailure: null,

  jawabanRequest: ["data"],
  jawabanSuccess: ["payload"],
  jawabanFailure: null,

  detailJawabanRequest: ["data"],
  detailJawabanSuccess: ["payload"],
  detailJawabanFailure: null,

  hasilRequest: ["data"],
  hasilSuccess: ["payload"],
  hasilFailure: null,

  hasilTreeRequest: ["data"],
  hasilTreeSuccess: ["payload"],
  hasilTreeFailure: null,

  getHasilRequest: ["data"],
  getHasilSuccess: ["payload"],
  getHasilFailure: null,

  listHasilRequest: ["data"],
  listHasilSuccess: ["payload"],
  listHasilFailure: null,

  hasilGrafikRequest: ["data"],
  hasilGrafikSuccess: ["payload"],
  hasilGrafikFailure: null,

  saveNilaiRequest: ["data"],
  saveNilaiSuccess: ["payload"],
  saveNilaiFailure: null,
});

export const HasilTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  dataSoal: null,
  dataJawaban: null,
  dataHasil: null,
  dataListHasil: null,
  dataGrafik: null,
  fetching: null,
  payloadSoal: [],
  payloadJawaban: [],
  payloadHasil: null,
  payloadListHasil: [],
  payloadGrafik: [],

  dataDetailJawaban: null,
  payloadDetailJawaban: null,

  dataSaveNilai: null,
  payloadSaveNilai: null,
  error: null,
});

/* ------------- Selectors ------------- */

export const HasilSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

export const requestSoal = (state, action) =>
  state.merge({ fetching: true, dataSoal: action.data });

export const successSoal = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadSoal: action.payload,
  });
};

export const requestJawaban = (state, action) =>
  state.merge({ fetching: true, dataJawaban: action.data });

export const successJawaban = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadJawaban: action.payload,
  });
};

export const requestDetailJawaban = (state, action) =>
  state.merge({ fetching: true, dataDetailJawaban: action.data });

export const successDetailJawaban = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadDetailJawaban: action.payload,
  });
};

export const requestHasil = (state, action) =>
  state.merge({ fetching: true, dataHasil: action.data });

export const successHasil = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadHasil: action.payload,
  });
};

export const requestListHasil = (state, action) =>
  state.merge({ fetching: true, dataListHasil: action.data });

export const successListHasil = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadListHasil: action.payload,
  });
};

export const requestGrafikHasil = (state, action) =>
  state.merge({ fetching: true, dataGrafik: action.data });

export const successGrafikHasil = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadGrafik: action.payload,
  });
};

export const requestSaveNilai = (state, action) =>
  state.merge({ fetching: true, dataSaveNilai: action.data });

export const successSaveNilai = (state, action) => {
  return state.merge({
    fetching: false,
    error: null,
    payloadSaveNilai: action.payload,
  });
};

export const failure = (state) => state.merge({ fetching: false, error: true });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SOAL_REQUEST]: requestSoal,
  [Types.SOAL_SUCCESS]: successSoal,
  [Types.SOAL_FAILURE]: failure,

  [Types.JAWABAN_REQUEST]: requestJawaban,
  [Types.JAWABAN_SUCCESS]: successJawaban,
  [Types.JAWABAN_FAILURE]: failure,

  [Types.DETAIL_JAWABAN_REQUEST]: requestDetailJawaban,
  [Types.DETAIL_JAWABAN_SUCCESS]: successDetailJawaban,
  [Types.DETAIL_JAWABAN_FAILURE]: failure,

  [Types.HASIL_REQUEST]: requestHasil,
  [Types.HASIL_SUCCESS]: successHasil,
  [Types.HASIL_FAILURE]: failure,

  [Types.GET_HASIL_REQUEST]: requestHasil,
  [Types.GET_HASIL_SUCCESS]: successHasil,
  [Types.GET_HASIL_FAILURE]: failure,

  [Types.HASIL_TREE_REQUEST]: requestHasil,
  [Types.HASIL_TREE_SUCCESS]: successHasil,
  [Types.HASIL_TREE_FAILURE]: failure,

  [Types.LIST_HASIL_REQUEST]: requestListHasil,
  [Types.LIST_HASIL_SUCCESS]: successListHasil,
  [Types.LIST_HASIL_FAILURE]: failure,

  [Types.HASIL_GRAFIK_REQUEST]: requestGrafikHasil,
  [Types.HASIL_GRAFIK_SUCCESS]: successGrafikHasil,
  [Types.HASIL_GRAFIK_FAILURE]: failure,

  [Types.SAVE_NILAI_REQUEST]: requestSaveNilai,
  [Types.SAVE_NILAI_SUCCESS]: successSaveNilai,
  [Types.SAVE_NILAI_FAILURE]: failure,
});
