import React from "react";
import { Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import TreeTes from "./Dashboards/Tree/TreeTest";
// import Psikotes from "./Dashboards/Psikotes/index";
import asyncComponent from "util/asyncComponent";
// import Perusahaan from "./Perusahaan";
import GrafikTree from "./Dashboards/Tree/Grafik";
import HasilTree from "./Dashboards/Tree/Hasil";
import { AdminRoute, SiswaRoute } from "./protectedroutes";

const App = ({ match }) => {
  // const iconSrc = "assets/images/daftar-perusahaan/";

  // const listPerusahaan = [
  //   {
  //     name: "PT DENSO INDONESIA",
  //     date: "19-2-22 s/d 21-2-22",
  //     slot: "1285/100",
  //     icon: iconSrc + "ptdensoindonesia.png",
  //   },
  //   {
  //     name: "PT ASTRA GROUP",
  //     date: "19-2-22 s/d 21-2-22",
  //     slot: "1680/150",
  //     icon: iconSrc + "ptastragroup.png",
  //   },
  //   {
  //     name: "PT Toyota Astra Motor",
  //     date: "19-2-22 s/d 21-2-22",
  //     slot: "1285/100",
  //     icon: iconSrc + "pttoyotaastramotor.png",
  //   },
  //   {
  //     name: "PT Yamaha Motor",
  //     date: "19-2-22 s/d 21-2-22",
  //     slot: "1680/150",
  //     icon: iconSrc + "ptyamahamotor.png",
  //   },
  //   {
  //     name: "PT Suzuki Indomobil",
  //     date: "19-2-22 s/d 21-2-22",
  //     slot: "1680/150",
  //     icon: iconSrc + "ptsuzukiindomobil.png",
  //   },
  // ];

  // const [selectedPerusahaan, setSelectedPerusahaan] = useState(
  //   listPerusahaan[0]
  // );
  // const onSelectedPerusahaanHandler = (value) => {
  //   setSelectedPerusahaan(value);
  // };

  // let urlPerusahaan = selectedPerusahaan.name.toLowerCase().replace(/ /g, "");
  // //console.log(urlPerusahaan);

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}samplePage`}
          component={asyncComponent(() => import("./SamplePage"))}
        />
        {/* <InitialRoute
          path={`${match.url}`}
          // componentSiswa={asyncComponent(() => import("./Dashboards"))}
          // componentAdmin={asyncComponent(() => import("./Admin/DaftarSoal"))}
        /> */}
        {/* yang ada di sidebar */}
        {/* <AdminRoute
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboards"))}
        /> */}
        <SiswaRoute
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboards"))}
        />
        <SiswaRoute
          path={`${match.url}daftarsoal/:id`}
          component={asyncComponent(() =>
            import("../components/TestingApp/TestingListSoal")
          )}
        />
        <SiswaRoute
          path={`${match.url}hasil`}
          component={asyncComponent(() =>
            import("../components/TestingApp/ListEventHasil")
          )}
        />
        <SiswaRoute
          path={`${match.url}listHasilUjian`}
          component={asyncComponent(() => import("./Hasil"))}
        />
        {/* daftar form perusahaan */}
        <SiswaRoute
          path={`${match.url}event/:id`}
          component={asyncComponent(() =>
            import("./Perusahaan/DaftarPerusahaan/PTFormId")
          )}
        />
        <SiswaRoute
          path={`${match.url}event`}
          component={asyncComponent(() => import("./Perusahaan"))}
        />
        {/* <Perusahaan
            listPerusahaan={listPerusahaan}
            onSelectedPerusahaanHandler={onSelectedPerusahaanHandler}
          /> */}
        {/* </SiswaRoute> */}

        {/* yang ada di halaman dashboard */}
        {/* wartegg */}
        <SiswaRoute
          path={`${match.url}warttegtest/hasil`}
          component={asyncComponent(() =>
            import("./Dashboards/WarttegTes/hasil")
          )}
        />
        <SiswaRoute
          path={`${match.url}warttegtesthasil`}
          component={asyncComponent(() =>
            import("./Dashboards/WarttegTes/hasil2")
          )}
        />
        <SiswaRoute
          path={`${match.url}grafikWarttegTest`}
          component={asyncComponent(() =>
            import("./Dashboards/WarttegTes/Grafik")
          )}
        />
        <SiswaRoute
          path={`${match.url}warttegtest`}
          component={asyncComponent(() => import("./Dashboards/WarttegTes"))}
        />


        {/* Minat Karir Tes */}
        <SiswaRoute
          path={`${match.url}MinatKarirTes/hasil`}
          component={asyncComponent(() =>
            import("./Dashboards/MinatKarirTes/hasil")
          )}
        />
        <SiswaRoute
          path={`${match.url}MinatKarirTeshasil`}
          component={asyncComponent(() =>
            import("./Dashboards/MinatKarirTes/hasil2")
          )}
        />
        <SiswaRoute
          path={`${match.url}minatkarirtes`}
          component={asyncComponent(() => import("./Dashboards/MinatKarirTes"))}
        />

        {/* skolastic */}
        <SiswaRoute
          path={`${match.url}grafikSkolasticTest`}
          component={asyncComponent(() =>
            import("./Dashboards/Skolastic/grafik")
          )}
        />
        <SiswaRoute
          path={`${match.url}skolastictest/hasil`}
          component={asyncComponent(() =>
            import("./Dashboards/Skolastic/Hasil")
          )}
        />
        <SiswaRoute
          path={`${match.url}skolastictesthasil`}
          component={asyncComponent(() =>
            import("./Dashboards/Skolastic/Hasil2")
          )}
        />
        <SiswaRoute
          path={`${match.url}skolastictest`}
          component={asyncComponent(() =>
            import("./Dashboards/Skolastic/index")
          )}
        />
        <SiswaRoute
          path={`${match.url}skolastictestc`}
          component={asyncComponent(() =>
            import("./Dashboards/Skolastic/SekolaticC")
          )}
        />


        {/* logika test */}
        <SiswaRoute
          path={`${match.url}logikatest/hasil`}
          component={asyncComponent(() =>
            import("./Dashboards/LogikaTes/hasil")
          )}
        />
        <SiswaRoute
          path={`${match.url}logikatesthasil`}
          component={asyncComponent(() =>
            import("./Dashboards/LogikaTes/hasil2")
          )}
        />
        <SiswaRoute
          path={`${match.url}grafikLogikaTest`}
          component={asyncComponent(() =>
            import("./Dashboards/LogikaTes/chartlogika/chartlogika")
          )}
        />
        <SiswaRoute
          path={`${match.url}logikatest`}
          component={asyncComponent(() => import("./Dashboards/LogikaTes"))}
        />

        {/* tiutest */}
        <SiswaRoute
          path={`${match.url}tiuTest/hasil`}
          component={asyncComponent(() => import("./Dashboards/TiuTes/Hasil"))}
        />
        <SiswaRoute
          path={`${match.url}tiuTest/guide`}
          component={asyncComponent(() =>
            import("./Dashboards/TiuTes/TiuTest")
          )}
        />
        <SiswaRoute
          path={`${match.url}tiuTest`}
          component={asyncComponent(() =>
            import("./Dashboards/TiuTes/TiuTes1")
          )}
        />
        <SiswaRoute
          path={`${match.url}grafikTiuTest`}
          component={asyncComponent(() => import("./Dashboards/TiuTes/Grafik"))}
        />
        <SiswaRoute
          path={`${match.url}tiuTesthasil`}
          component={asyncComponent(() => import("./Dashboards/TiuTes/Hasil2"))}
        />
        {/* psikotes */}
        {/* Andika */}
        {/* Ini SiswaRoute yang nantinya akan mengarah ke index.js pd masing-masing komponen */}
        <SiswaRoute
          path={`${match.url}grafikPsikotes`}
          component={asyncComponent(() =>
            import("./Dashboards/Psikotes/Grafik")
          )}
        />
        <SiswaRoute
          path={`${match.url}psikotesHasil`}
          component={asyncComponent(() =>
            import("./Dashboards/Psikotes/Hasil2")
          )}
        />
        <SiswaRoute
          path={`${match.url}psikotes/hasil`}
          component={asyncComponent(() =>
            import("./Dashboards/Psikotes/Hasil")
          )}
        />
        <SiswaRoute
          path={`${match.url}psikotes`}
          // ambil import
          component={asyncComponent(() => import("./Dashboards/Psikotes"))}
        />

        {/* treetest */}
        <SiswaRoute path={`${match.url}treetest/hasil`} component={HasilTree} />
        <SiswaRoute
          path={`${match.url}treetesthasil`}
          component={asyncComponent(() => import("./Dashboards/Tree/Hasil2"))}
        />
        <SiswaRoute
          path={`${match.url}grafikTreetest`}
          component={GrafikTree}
        />
        
        <SiswaRoute path={`${match.url}treetest`} component={TreeTes} />

        {/* jika url tidak ditemukan akan di redirect ke /dashboard (sementara ke dashboard, belum bikin komponen 404) */}
        {/* <Route
          path={`${match.url}404`}
          component={asyncComponent(() => import("../containers/NotFound.js"))}
        />
        <Redirect to="/404" /> */}

        {/* admin */}
        <AdminRoute
          path={`${match.url}soal`}
          component={asyncComponent(() => import("./Admin/DaftarSoal"))}
        />
        <AdminRoute
          path={`${match.url}listEvent`}
          component={asyncComponent(() => import("./Admin/Event/ListEvent"))}
        />
        <AdminRoute
          path={`${match.url}detailEvent/:id`}
          component={asyncComponent(() => import("./Admin/Event/detailEvent"))}
        />

        {/*  koreksi */}
        <AdminRoute
          path={`${match.url}koreksi`}
          component={asyncComponent(() =>
            import("./Admin/KoreksiSoal/KoreksiEvent")
          )}
        />
        <AdminRoute
          path={`${match.url}listPeserta/:id`}
          component={asyncComponent(() =>
            import("./Admin/KoreksiSoal/ListPeserta")
          )}
        />
        <AdminRoute
          path={`${match.url}nilaiJawaban/:id`}
          component={asyncComponent(() =>
            import("./Admin/KoreksiSoal/NilaiUjian")
          )}
        />
        <AdminRoute
          path={`${match.url}hasil_event`}
          component={asyncComponent(() => import("./Admin/Hasil/HasilEvent"))}
        />
        <AdminRoute
          path={`${match.url}list_peserta/:id`}
          component={asyncComponent(() => import("./Admin/Hasil/ListPeserta"))}
        />
        <AdminRoute
          path={`${match.url}detailpeserta/:eventId/:mUserId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/DetailPeserta")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/Psikotes/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/Psikotes")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/PsikotesGrafik/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/Psikotes/grafik")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/TreeTest/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/TreeTes/hasil")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/TreeTestGrafik/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/TreeTes/grafik")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/TiuTest/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/TiuTes")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/TiuTestGrafik/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/TiuTes/grafik")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/LogikaTest/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/LogikaTes/hasil")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/LogikaTestGrafik/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/LogikaTes/chartlogika/chartlogika")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/SkolasticTest/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/SkolasticTes/Hasil2")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/SkolasticTestGrafik/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/SkolasticTes/grafik")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/WarttegTest/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/WarttegTest/hasil2")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/WarttegTestGrafik/:eventId/:mUserId/:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/WarttegTest/Grafik")
          )}
        />
        <AdminRoute
          path={`${match.url}hasilpeserta/Minatkarirtes/:eventId/:mUserId:jenisSoalId`}
          component={asyncComponent(() =>
            import("./Admin/Hasil/HasilSoal/MinatKarirTes/hasil2"))}
        />
        {/* admin */}
      </Switch>
    </div>
  );
};

export default App;
