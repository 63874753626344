// const TARGET = process.env.REACT_APP_API_URL || 'localhost';
// const PORT = process.env.REACT_APP_API_PORT || 1337;
// const HTTP = process.env.REACT_APP_API_HTTP || 'http';
const BASE_URL_AUTH =
  process.env.NODE_ENV === "development"
    ? // ? "http://api.testingapp.erp-mpe.com:8081/auth/"
      "https://api.dayauji.com/auth/"
    : "https://api.dayauji.com/auth/"; //'https://my-json-server.typicode.com/';
const BASE_URL_DATA =
  process.env.NODE_ENV === "development"
    ? // ? "http://api.testingapp.erp-mpe.com:8081/data/"
      "https://api.dayauji.com/data/"
    : "https://api.dayauji.com/data/"; //'https://my-json-server.typicode.com/';
const BASE_URL_SOAL =
  process.env.NODE_ENV === "development"
    ? // ? "http://api.testingapp.erp-mpe.com:8081/soal/"
      "https://api.dayauji.com/soal/"
    : "https://api.dayauji.com/soal/";

// const BASE_URL = (process.env.REACT_APP_ISDEV === 'yes') ? "https://simbio.erp-mpe.com/api/" : "https://api.agriva.id/bioflok/"; //'https://my-json-server.typicode.com/';
// const BASE_URL_AUTH = (process.env.REACT_APP_ISDEV === 'yes') ? "https://simbio.erp-mpe.com/apiauth/" : 'https://api.agriva.id/auth/';
// const BASE_URL_UPLOAD = (process.env.REACT_APP_ISDEV === 'yes') ? "https://simbio.erp-mpe.com/apifile/" : 'https://api.agriva.id/file/'; //'https://my-json-server.typicode.com/';
// const SAMPLE_API_URL = (process.env.REACT_APP_ISDEV === 'yes') ? "http://8.215.32.207/testprovider/" : "http://8.215.32.207/testprovider/"; //'https://my-json-server.typicode.com/';

const SAMPLE_API_URL = "http://8.215.32.207/testprovider/"; //'https://my-json-server.typicode.com/';
console.log("isDev", process.env.NODE_ENV);
module.exports = {
  footerText: "Copyright Company Name © 2022",
  BASE_URL_AUTH,
  BASE_URL_DATA,
  BASE_URL_SOAL,
  SAMPLE_API_URL,
  IS_SESSION_TIMEOUT_ACTIVE: false,
  SESSION_TIMEOUT: 1000 * 60 * 9, //

  CAPCTCHA_CHANCES: 2,
  HOLD_TIME: 5,

  tablePageSize: 10,

  timeOut: 1000 * 1800,
};
