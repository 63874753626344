import { message } from "antd";
import { call, put } from "redux-saga/effects";
import HasilActions from "../reducers/HasilRedux";

export function* soalRequest(api, action) {
  const { data } = action;
  const response = yield call(api.getsoal, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(HasilActions.soalSuccess(response.data.data));
  } else {
    yield put(HasilActions.soalFailure());
  }
}

export function* jawabanRequest(api, action) {
  const { data } = action;
  const response = yield call(api.getjawaban, data);
  if (response.ok) {
    //console.log("jawaban response.data", response.data.data);
    yield put(HasilActions.jawabanSuccess(response.data.data));
  } else {
    yield put(HasilActions.jawabanFailure());
  }
}

export function* detailJawabanRequest(api, action) {
  const { data } = action;
  const response = yield call(api.getdetailjawaban, data);
  if (response.ok) {
    //console.log("detail jawaban response.data", response.data.data);
    yield put(HasilActions.detailJawabanSuccess(response.data.data));
  } else {
    yield put(HasilActions.detailJawabanFailure());
  }
}

export function* hasilRequest(api, action) {
  const { data } = action;
  const response = yield call(api.gethasil, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(HasilActions.hasilSuccess(response.data.data));
  } else {
    yield put(HasilActions.hasilFailure());
  }
}

export function* hasilTreeRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.mhasilujian, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(
      HasilActions.hasilTreeSuccess(
        response.data.data.length == 0 ? null : response.data.data[0]
      )
    );
  } else {
    yield put(HasilActions.hasilTreeFailure());
  }
}

export function* getHasilRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.mhasilujian, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(
      HasilActions.getHasilSuccess(
        response.data.data.length == 0 ? null : response.data.data[0]
      )
    );
  } else {
    yield put(HasilActions.getHasilFailure());
  }
}

export function* listHasilRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.mhasilujian, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(HasilActions.listHasilSuccess(response.data.data));
  } else {
    yield put(HasilActions.listHasilFailure());
  }
}

export function* hasilGrafikRequest(api, action) {
  const { data } = action;
  // const history = data.history;
  const response = yield call(api.getgrafikdata, data);
  if (response.ok) {
    // //console.log("template response.data", response.data);
    yield put(HasilActions.hasilGrafikSuccess(response.data.data));
    // history.push(data.url);
  } else {
    yield put(HasilActions.hasilGrafikFailure());
  }
}

export function* saveNilaiRequest(api, action) {
  const { data } = action;
  const history = action.data.history;
  //console.log(data);
  const response = yield call(api.savenilai, data);
  if (response.ok) {
    //console.log("saveNilai response.data", response.data);
    yield put(HasilActions.saveNilaiSuccess(response.data));
    message.success("Nilai tersimpan");
    history.goBack();
  } else {
    yield put(HasilActions.saveNilaiFailure());
  }
}
