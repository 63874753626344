import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import EventAction from "../../../appRedux/reducers/EventRedux";
import "./styles.less";
import { decrypt, encrypt } from "../../../lib/crypto";

const ListPeserta = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const [dataSearch, setDataSearch] = useState({
    event_id: decrypt(searchParams.get("e")),
    search: null,
    asal_sekolah:
      decrypt(searchParams.get("s")) == "undefined"
        ? null
        : decrypt(searchParams.get("s")),
    keahlian:
      decrypt(searchParams.get("k")) == "undefined"
        ? null
        : decrypt(searchParams.get("k")),
    offset: 0,
    limit: 10,
  });
  console.log(dataSearch);
  useEffect(() => {
    dispatch(EventAction.getSekolahRequest());
  }, []);
  useEffect(() => {
    dataSearch.event_id && dispatch(EventAction.getPesertaRequest(dataSearch));
  }, [dataSearch]);
  const eventstate = useSelector((state) => state.event);
  const [pageTable, setPageTable] = useState(1);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (value, item, index) => (pageTable - 1) * 10 + index + 1,
      align: "center",
      width: "80px",
    },
    {
      title: "Nama",
      dataIndex: "nama",
      key: "nama",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Asal sekolah",
      dataIndex: "asal_sekolah",
      key: "asal_sekolah",
      align: "center",
    },
    {
      title: "Usia",
      dataIndex: "usia",
      key: "usia",
      align: "center",
    },
    {
      title: "Keahlian",
      dataIndex: "keahlian",
      key: "keahlian",
      align: "center",
    },
    {
      title: "Alamat",
      dataIndex: "alamat_lengkap",
      key: "alamat_lengkap",
      align: "center",
    },
    {
      title: "Aksi",
      key: "aksi",
      fixed: "right",
      align: "center",
      render: (value) => (
        <Button
          onClick={() =>
            history.push(
              `/dp?e=${encrypt(value.event_id)}&m=${encrypt(value.m_user_id)}`
            )
          }
          type="primary"
        >
          Detail
        </Button>
      ),
      width: 100,
    },
  ];
  const handleTableChange = (pagination, filters, sorter, extra) => {
    //console.log("offset", (pagination.current - 1) * 10);
    setDataSearch({ ...dataSearch, offset: (pagination.current - 1) * 10 });
    setPageTable(pagination.current);
  };
  console.log(decrypt(searchParams.get("s")));
  console.log(params);
  return (
    <div style={{ padding: 20 }}>
      <Card title="Daftar peserta">
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Typography.Text>Search : </Typography.Text>
            <Input.Search
              onSearch={(val) => setDataSearch({ ...dataSearch, search: val })}
              // onChange={(val) =>
              //   setDataSearch({ ...dataSearch, search: val.target.value })
              // }
              style={{ marginTop: 5, marginBottom: 20 }}
              placeholder="cari..."
            />
          </Col>
          {/* <Col span={6}>
            <Typography.Text>Asal sekolah : </Typography.Text>
            <br />
            <Select
              style={{ marginTop: 5, marginBottom: 20, width: "100%" }}
              onChange={(val) =>
                setDataSearch({ ...dataSearch, asal_sekolah: val })
              }
              // showSearch
              placeholder="Asal sekolah"
            >
              {eventstate.payloadSekolah &&
                eventstate.payloadSekolah.data.map((e) => {
                  return (
                    <Option key={e._id} value={e.nama_sekolah}>
                      {e.nama_sekolah}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col span={6}>
            <Typography.Text>Keahlian : </Typography.Text>
            <br />
            <Select
              style={{ marginTop: 5, marginBottom: 20, width: "100%" }}
              onChange={(val) =>
                setDataSearch({ ...dataSearch, keahlian: val })
              }
              // showSearch
              placeholder="Kompetensi Keahlian"
            >
              <Option value="Akuntansi">Akuntansi</Option>
              <Option value="OTKP">OTKP</Option>
              <Option value="DKV">DKV</Option>
              <Option value="RPL">RPL</Option>
              <Option value="TKJ">TKJ</Option>
              <Option value="Tata Busana">Tata Busana</Option>
              <Option value="Teknik Pemesinan">Teknik Pemesinan</Option>
              <Option value="Teknik Pengelasan">Teknik Pengelasan</Option>
              <Option value="Tek.Kendarsan Ringan (Otomotif)">
                Tek.Kendarsan Ringan (Otomotif)
              </Option>
            </Select>
          </Col> */}
        </Row>
        {eventstate.fetching ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            className="table-style"
            scroll={{ x: 1100 }}
            dataSource={
              eventstate.payloadpesertaevent &&
              eventstate.payloadpesertaevent.data
            }
            rowKey={(record) => record._id}
            columns={columns}
            pagination={{
              current: pageTable,
              pageSize: 10,
              total:
                eventstate.payloadpesertaevent &&
                eventstate.payloadpesertaevent.total,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
          />
        )}
      </Card>
    </div>
  );
};

export default ListPeserta;
