import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  changeJawabanPsikotes: ["data"],
  changeJawabanTiutes: ["data"],
});

export const JawabanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  jawabanPsikotes: [],
  jawabanTiutes: [],
});

/* ------------- Selectors ------------- */

export const JawabanSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

export const psikotes = (state, action) =>
  state.merge({ jawabanPsikotes: action.data });
export const tiutes = (state, action) =>
  state.merge({ jawabanTiutes: action.data });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_JAWABAN_PSIKOTES]: psikotes,
  [Types.CHANGE_JAWABAN_TIUTES]: tiutes,
});
