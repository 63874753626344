import React from "react";
import { Spin } from "antd";

const CircularProgress = ({ className }) => (
  // <div className={`loader ${className}`}>
  <div>
    {/* <img src="/assets/images/loader.svg" alt="loader"/> */}
    <div
      style={{
        margin: "20px 0",
        marginBottom: "20px",
        padding: "30px 50px",
        textAlign: "center",
        background: "rgba(0, 0, 0, 0.05)",
        borderRadius: "4px",
      }}
    >
      <Spin />
    </div>
  </div>
);
export default CircularProgress;
