// import { message, Modal } from "antd";s
import { call, put } from "redux-saga/effects";
import SoalActions from "../reducers/SoalRedux";
// import LocalActions from "../reducers/LocalRedux";
import HasilActions from "../reducers/HasilRedux";
import JawabanAction from "../reducers/JawabanRedux";
import { message } from "antd";
import jawaban_siswa from "../../routes/Dashboards/Psikotes/arrayJawaban";
import jawaban_siswa_tiu from "../../routes/Dashboards/TiuTes/arrayjawabantiu";
import _ from "lodash";

export function* jenisSoalRequest(api, action) {
  const { data } = action;
  const response = yield call(api.getJenisSoal, data);
  if (response.ok) {
    yield put(SoalActions.jenisSoalSuccess(response.data.data));
  } else {
    yield put(SoalActions.jenisSoalFailure());
  }
}

//  treetes
export function* getTreetesRequest(api, action) {
  const { data } = action;
  // const history = data.history;
  const response = yield call(api.treetes, data);
  if (response.ok) {
    yield put(SoalActions.getTreetesSuccess(response.data.data));
  } else {
    yield put(SoalActions.getTreetesFailure());
  }
}

export function* getPsikotesRequest(api, action) {
  const { data } = action;
  // console.log(data);
  // //console.log("page", data.page);
  if (data.page == 0) {
    //console.log(data.history);
    const history = data.history;
    history.replace(`/${data.jenis_soal}/hasil`);

    yield put(SoalActions.getPsikotesSuccess(null));
    return;
  }
  const response = yield call(api.getsoal, {
    page: data.page,
    jenis_soal_id: data.jenis_soal_id,
    event_id: data.event_id,
    m_user_id: data.m_user_id,
  });
  if (response.ok) {
    // //console.log("psikotes soal", response.data.data);
    yield put(SoalActions.getPsikotesSuccess(response.data.data));
  } else {
    yield put(SoalActions.getPsikotesFailure());
  }
}

export function* getPageRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.getpage, data);
  if (response.ok) {
    // //console.log("psikotes soal", response.data.data);
    yield put(SoalActions.getPageSuccess(response.data.data));
    yield put(
      SoalActions.getPsikotesRequest({
        page: response.data.data.page,
        history: data.history,
        jenis_soal_id: data.jenis_soal_id,
        event_id: data.event_id,
        jenis_soal: data.jenis_soal,
        m_user_id: data.m_user_id,
      })
    );
  } else {
    yield put(SoalActions.getPageFailure());
  }
}

export function* deleteAllPageRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.deletesavepage, data);
  if (response.ok) {
    // yield put(SoalActions.deletePageRequest(data));
    yield put(SoalActions.deletePageSuccess());
    message.success("Semua riwayat soal siswa dihapus");
  } else {
    yield put(SoalActions.deletePageFailure());
  }
}

export function* nextPageRequest(api, action) {
  const { data } = action;
  //console.log(data);
  const response = yield call(api.nextpage, { _id: data._id });
  if (response.ok) {
    // //console.log("psikotes soal", response.data.data);
    yield put(SoalActions.nextPageSuccess(response.data.data));
    yield put(
      SoalActions.getPsikotesRequest({
        page: response.data.data.page,
        history: data.history,
        jenis_soal_id: data.jenis_soal_id,
        event_id: data.event_id,
        jenis_soal: data.jenis_soal,
      })
    );
    yield put(
      SoalActions.soalBerlangsungRequest({
        m_user_id: data.m_user_id,
        event_id: data.event_id,
      })
    );
  } else {
    yield put(SoalActions.nextPageFailure());
  }
}

export function* saveJawabanRequest(api, action) {
  const { data } = action;
  //console.log(data);
  // const user = JSON.parse(localStorage.getItem("user_credent"));
  const response = yield call(api.savejawaban, data);
  if (response.ok) {
    // //console.log("psikotes", response.data.data);
    yield put(SoalActions.saveJawabanSuccess());
    yield put(
      SoalActions.nextPageRequest({
        history: data.history,
        _id: data.datapage_id,
        jenis_soal_id: data.jenis_soal_id,
        event_id: data.event_id,
        jenis_soal: data.jenis_soal,
      })
    );
    yield put(
      HasilActions.hasilRequest({
        jenis_soal_id: data.jenis_soal_id,
        m_user_id: data.m_user_id,
        event_id: data.event_id,
        jenis_soal: data.jenis_soal,
      })
    );
    yield put(
      SoalActions.saveGrafikRequest({
        m_user_id: data.m_user_id,
        event_id: data.event_id,
        jenis_soal_id: data.jenis_soal_id,
        page: data.page,
        waktu: data.waktu,
      })
    );
    yield put(JawabanAction.changeJawabanPsikotes(jawaban_siswa));
    yield put(JawabanAction.changeJawabanTiutes(jawaban_siswa_tiu));
  } else {
    yield put(SoalActions.saveJawabanFailure());
  }
}

export function* getTimeRequest(api, action) {
  const { data } = action;
  //console.log(data);
  const response = yield call(api.gettime, data);
  if (response.ok) {
    //console.log(response.data.data);
    yield put(SoalActions.getTimeSuccess(response.data.data));
  } else {
    yield put(SoalActions.getTimeFailure());
  }
}

export function* saveTimeRequest(api, action) {
  const { data } = action;
  //console.log(data);
  const response = yield call(api.savetime, data);
  if (response.ok) {
    // //console.log(response.data.data);
    yield put(SoalActions.saveTimeSuccess(response.data.data));
  } else {
    yield put(SoalActions.saveTimeFailure());
  }
}

export function* refreshTimeRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.refreshtime, data);
  if (response.ok) {
    // //console.log(response.data.data);
    yield put(SoalActions.refreshTimeSuccess(response.data.data));
  } else {
    yield put(SoalActions.resfreshTimeFailure());
  }
}

export function* soalBerlangsungRequest(api, action) {
  const { data } = action;
  const response = yield call(api.soalberlangsung, data);
  if (response.ok) {
    yield put(SoalActions.soalBerlangsungSuccess(response.data.data));
  } else {
    yield put(SoalActions.soalBerlangsungFailure());
  }
}

export function* saveGrafikRequest(api, action) {
  const { data } = action;
  const response = yield call(api.savegrafikdata, data);
  if (response.ok) {
    yield put(SoalActions.saveGrafikSuccess());
  } else {
    yield put(SoalActions.saveGrafikFailure());
  }
}

export function* getAllSoalRequest(api, action) {
  const { data } = action;
  //console.log(data);
  const response = yield call(api.getAllSoal, data);
  if (response.ok) {
    // //console.log(response.data.data);
    yield put(
      SoalActions.getAllSoalSuccess({
        data: _.sortBy(response.data.data, "createdAt").reverse(),
        total: response.data.total,
      })
    );
  } else {
    yield put(SoalActions.getAllSoalFailure());
  }
}

export function* getSoalEventRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.getSoalbyEvent, data);
  if (response.ok) {
    yield put(
      SoalActions.getSoalEventSuccess(
        _.sortBy(response.data.data, "createdAt").reverse()
      )
    );
  } else {
    yield put(SoalActions.getSoalEventFailure());
  }
}

export function* addSoalEventRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.addSoalEvent, data);
  if (response.ok) {
    yield put(
      SoalActions.getSoalEventRequest({
        event_id: data.event_id,
        jenis_soal_id: data.jenis_soal_id,
      })
    );
    yield put(SoalActions.addSoalEventSuccess(response.data.data));
    message.success("Soal berhasil ditambahkan");
  } else {
    yield put(SoalActions.addSoalEventFailure());
  }
}

export function* deleteSoalEventRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.deleteSoalEvent, data);
  if (response.ok) {
    yield put(
      SoalActions.getSoalEventRequest({
        event_id: data.event_id,
        jenis_soal_id: data.jenis_soal_id,
      })
    );
    yield put(SoalActions.deleteSoalEventSuccess(response.data.data));
    message.success("Soal berhasil dihapus");
  } else {
    yield put(SoalActions.deleteSoalEventFailure());
  }
}

export function* deleteAllSoalEventRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.deleteSoalEvent, data);
  if (response.ok) {
    yield put(SoalActions.getSoalEventRequest(data));
    yield put(SoalActions.deleteSoalEventSuccess(response.data.data));
    message.success("Soal terdaftar berhasil dihapus");
  } else {
    yield put(SoalActions.deleteSoalEventFailure());
  }
}

export function* createSoalRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.createSoal, data);
  if (response.ok) {
    yield put(SoalActions.createSoalSuccess());
    // yield put(
    //   SoalActions.getAllSoalRequest({
    //     jenis_soal_id: data.jenis_soal_id,
    //     limit: 10,
    //   })
    // );
    message.success("Soal berhasil dibuat");
  } else {
    yield put(SoalActions.createSoalFailure());
  }
}

export function* deleteSoalRequest(api, action) {
  const { data } = action;
  // //console.log(data);
  const response = yield call(api.deleteSoal, data);
  if (response.ok) {
    yield put(SoalActions.deleteSoalSuccess());
    yield put(
      SoalActions.getAllSoalRequest({
        jenis_soal_id: data.jenis_soal_id,
        limit: 5,
        offset: data.offset,
      })
    );
    message.success("Soal berhasil dihapus");
  } else {
    yield put(SoalActions.deleteSoalFailure());
  }
}
