import React, { useEffect } from "react";
import "./WartegTes.css";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";
import { Spin } from "antd";
import { useRouteMatch } from "react-router-dom";

const Hasil = () => {
  const hasilstate = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  useEffect(() => {
    dispatch(
      HasilActions.soalRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
      })
    );
    dispatch(
      HasilActions.jawabanRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
        limit: 10000,
      })
    );
    dispatch(
      HasilActions.getHasilRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);

  return (
    <container>
      {hasilstate.payloadHasil && (
        <Row
          style={{
            marginBottom: 10,
            backgroundColor: "transparent",
            padding: 25,
          }}
        >
          <Col
            span={24}
            style={{
              height: 50,
              backgroundColor: "white",
              marginBottom: 20,
              borderRadius: 20,
              border: "4px solid #0e7178",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 10,
                top: 0,
                bottom: 0,
                zIndex: 999999,
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h5>
                Total Score :{" "}
                {hasilstate.payloadHasil && hasilstate.payloadHasil.nilai}%
              </h5>
            </div>

            <div
              style={{
                display: "flex",
                position: "absolute",
                alignItems: "center",
                width: `${
                  hasilstate.payloadHasil && hasilstate.payloadHasil.nilai
                }%`,
                height: "100%",
                backgroundColor: "rgba(0, 255, 148, 1)",
                // background:
                //   "linear-gradient(to right, red 10%, purple 45%, blue 85%)",
                left: 0,
                top: 0,
                paddingLeft: 10,
              }}
            ></div>
          </Col>
        </Row>
      )}
      {hasilstate.fetching ? (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <Spin size="large" />
        </div>
      ) : (
        hasilstate.payloadSoal.map((e) => {
          return (
            <div
              key={e._id}
              className="container011"
              style={{
                marginBottom: 20,
                background: "#fff",
                border: "4px solid #0E7178",
                borderRadius: "22px",
                padding: "32px",
              }}
            >
              <div className="">
                <Row>
                  {/* <div className="div011">
                    <div className="card011">WARTEG TES</div>
                  </div> */}
                  {
                    hasilstate.payloadJawaban.length != 0 &&
                      hasilstate.payloadJawaban.filter(
                        (x) => x.msoal_id == e._id
                      ).length != 0 && (
                        <h4 className="time01">
                          {hasilstate.payloadJawaban.filter(
                            (x) => x.msoal_id == e._id
                          )[0].skor == undefined
                            ? "Jawaban sedang di periksa"
                            : `Skor : ${
                                hasilstate.payloadJawaban.filter(
                                  (x) => x.msoal_id == e._id
                                )[0].skor
                              }`}
                          {/* Skor :{" "}
                        {
                          hasilstate.payloadJawaban.filter(
                            (x) => x.msoal_id == e._id
                          )[0].skor
                        } */}
                        </h4>
                      )
                    // : (
                    //   <h4 className="time01">Jawaban sedang di periksa</h4>
                    // )
                  }

                  <Col className="row0111">
                    <div className="box0111">
                      <img
                        alt=""
                        // className="img011"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={e.gambar}
                      ></img>
                    </div>
                  </Col>
                  <Col className="row0111">
                    <div className="box0111">
                      {hasilstate.payloadJawaban.length != 0 &&
                        hasilstate.payloadJawaban
                          .filter((x) => x.msoal_id == e._id)
                          .map((img) => {
                            return (
                              <div
                                key={img._id}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {img.jawaban_gambar != null ? (
                                  <img
                                    alt=""
                                    style={{ objectFit: "contain" }}
                                    className="img011"
                                    src={img.jawaban_gambar}
                                  ></img>
                                ) : (
                                  <p>Tidak menjawab</p>
                                )}
                              </div>
                            );
                          })}
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md={6}>
                  <p className="paragraf01">{e.soal_pertanyaan}</p>
                </Col>
              </Row>
            </div>
          );
        })
      )}
    </container>
  );
};

export default Hasil;
