import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
// import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
// import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
// import SamplePage from "../../routes/SamplePage";
import { useDispatch } from "react-redux";
import AuthActions from "../../appRedux/reducers/AuthRedux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SidebarContent.css";
import {
  // faSquarePollHorizontal,
  faArrowRightFromBracket,
  faGrip,
  faSquareCheck,
  faList,
} from "@fortawesome/free-solid-svg-icons";

import { toggleCollapsedSideNav } from "../../appRedux/actions";
import SubMenu from "antd/lib/menu/SubMenu";
import cyrb53 from "./../../lib/hasString";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const admin = cyrb53("admin");
  const siswa = cyrb53("siswa");
  const {
    // navStyle,
    themeType,
  } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const dispatch = useDispatch();

  const navCollapsed = useSelector(({ common }) => common.navCollapsed);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const user = JSON.parse(localStorage.getItem("user_credent"));
  // //console.log(user);

  // ngambil nama depan
  const firstName = user.nama.split(" ")[0];

  return (
    <div className="sidebar-menu">
      <div className="gx-sidebar-content border-0">
        {/* user profile */}
        <div className="sidebar-name-wrap pb-2">
          <div
            className="d-flex"
            style={{
              alignItems: "center",
            }}
          >
            <i
              className={`gx-icon-btn icon icon-${
                !sidebarCollapsed ? "menu" : "menu"
              } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
              onClick={() => {
                // tablet mode = hidden sidebar
                // desktop mode = small sidebar
                window.screen.availWidth > 991
                  ? setSidebarCollapsed(!sidebarCollapsed)
                  : dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
              style={{
                fontSize: "28px",
                height: "45px !important",
                width: "45px",
                margin: sidebarCollapsed ? "8px auto" : "8px 12px",
              }}
            />

            {/* sidebar collapsed = foto aja */}
            {sidebarCollapsed ? (
              ""
            ) : (
              <span
                className="sidebar-name text-center"
                title={user && user.nama}
              >{`Hii - ${
                // user && user.nama
                user && firstName
              }`}</span>
            )}
          </div>
          <UserProfile sidebarCollapsed={sidebarCollapsed}>
            {user.email}
          </UserProfile>
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            mode="inline"
            className="sidebar-content"
          >
            {user.role_name === siswa && (
              <>
                <Menu.Item key={"dashboard"} className="sidebar-list">
                  <Link to={"/dashboard"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faGrip}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">DASHBOARD</span>
                    )}
                  </Link>
                </Menu.Item>

                <Menu.Item key={"event"} className="sidebar-list">
                  <Link to={"/event"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faList}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">DAFTAR EVENT</span>
                    )}
                  </Link>
                </Menu.Item>
                <Menu.Item key={"hasil"} className="sidebar-list">
                  <Link to={"/hasil"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">HASIL</span>
                    )}
                  </Link>
                </Menu.Item>
                <Menu.Item key={"Latihan"} className="sidebar-list">
                  <Link to={"/Latihan"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">LATIHAN</span>
                    )}
                  </Link>
                </Menu.Item>
                <Menu.Item key={"Minatkarir"} className="sidebar-list">
                  <Link to={"/Minatkarir"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">MINAT KARIR</span>
                    )}
                  </Link>
                </Menu.Item>
              </>
            )}
            {/* admin */}
            {user.role_name === admin && (
              <>
                <Menu.Item key={"soal"} className="sidebar-list">
                  <Link to={"/soal"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">SOAL</span>
                    )}
                  </Link>
                </Menu.Item>
                <Menu.Item key={"listEvent"} className="sidebar-list">
                  <Link to={"/listEvent"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">EVENT</span>
                    )}
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key={"koreksi"} className="sidebar-list">
                  <Link to={"/koreksi"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">KOREKSI JAWABAN</span>
                    )}
                  </Link>
                </Menu.Item> */}
                <Menu.Item key={"hasil_event"} className="sidebar-list">
                  <Link to={"/hasil_event"}>
                    {sidebarCollapsed ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color="#fff"
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                        }}
                      />
                    ) : (
                      <span className="sidebar-title">HASIL PESERTA</span>
                    )}
                  </Link>
                </Menu.Item>
              </>
            )}
            {/* admin */}
          </Menu>
        </CustomScrollbars>
        <div
          onClick={() => dispatch(AuthActions.userSignOut())}
          className="sidebar-logout-btn"
          style={
            sidebarCollapsed
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : null

            // marginTop: sidebarCollapsed ? "-54px" : "0",
          }
        >
          {sidebarCollapsed ? (
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
          ) : (
            <span>Logout</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SidebarContent);
