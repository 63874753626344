import React, { useEffect } from "react";
import {
  // Button, Checkbox, message,
  Form,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styles from "./signin.module.css";

import {
  hideMessage,
  // showAuthLoader,
  // userFacebookSignIn,
  // userGithubSignIn,
  // userGoogleSignIn,
  // userSignIn,
  // userTwitterSignIn,
} from "../appRedux/actions";

import AuthActions from "../appRedux/reducers/AuthRedux";

// import CircularProgress from "../components/CircularProgress";
// import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
// import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
// import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
// import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import { Col, Row } from "react-bootstrap";
import ViewPortChecker from "../lib/ViewPortChecker";

const SignIn = () => {
  const dispatch = useDispatch();
  const {
    // loader, alertMessage,
    showMessage,
    authUser,
    loader,
  } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push("/");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    // //console.log("ini values: ", values);
    // alert("auth not configured");
    dispatch(AuthActions.signinUser(values, history));
    // dispatch(showAuthLoader());
    // dispatch(userSignIn(values));
  };

  return (
    <div className={styles.container} style={{ backgroundColor: "#182848" }}>
      <div className={styles.title}>
        <span>TESTING ONLINE</span>
      </div>
      <Row className={styles.row}>
        <Col md={6}>
          <div className={styles.left}>
              <img
                className={styles.images}
                src="assets\images\logo\dayauji_p.png"
                width={ViewPortChecker().isTabletOrMobile ? "150px" : "150px"}
                height={ViewPortChecker().isTabletOrMobile ? "120px" : ""}
                // 200:340
                // alt="logo FBKK"
                alt="logo dayaujic"
                style={{
                  objectFit: "cover"
                }}
              />
          </div>
        </Col>
        <Row className={styles.row}>
        <Col md={6}>
          <div className={styles.right}>
            <Form
              initialValues={{ remember: false }}
              name="formlogin"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className={`${styles.form} gx-signin-form gx-form-row0`}
            >
              <div className="text-center m-3 fw-bold">LOGIN</div>
              <Form.Item
                // initialValue="zull351@gmail.com"
                rules={[
                  { required: true, message: "The input is not valid E-mail!" },
                ]}
                name="email"
                bordered={false}
              >
                <Input bordered={false} placeholder="Email" />
              </Form.Item>
              <Form.Item
                // initialValue="123456"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
              >
                <Input
                  bordered={false}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <div className={styles["submit-btn"]}>
                  {loader ? (
                    <button disabled>Loading...</button>
                  ) : (
                    <button htmlType="submit">MASUK</button>
                  )}
                  <div>
                    Belum punya akun? <Link to="./signup">DAFTAR</Link>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Col>
        </Row>
      </Row>
      <Row>
        <Col>
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;

// import React from "react";
// import { Form, Col, Row, Card, Input, Button } from "antd";
// import { Container } from "react-bootstrap";
// import "./signin.css";

// const SignIn = () => {
//   const onFinish = (values) => {
//     //console.log("Received values of form: ", values);
//   };
//   return (
//     <Container className="login-container">
//       <div>
//         <Card className="login-title">RECRUITMENT TEST</Card>
//         <Row className="login-row">
//           <Col className="login-logo">Logo Sekolah</Col>
//           <Col className="login-input">
//             <div className="login-form">
//               <span>LOGIN</span>
//               <Form
//                 name="normal_login"
//                 className="login-form"
//                 initialValues={{
//                   remember: true,
//                 }}
//                 onFinish={onFinish}
//               >
//                 <Form.Item
//                   name="email"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please input your Email!",
//                     },
//                   ]}
//                 >
//                   <Input placeholder="Email" />
//                 </Form.Item>
//                 <Form.Item
//                   name="password"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Please input your Password!",
//                     },
//                   ]}
//                 >
//                   <Input type="password" placeholder="Password" />
//                 </Form.Item>

//                 <Form.Item>
//                   <Button
//                     type="primary"
//                     htmlType="submit"
//                     className="login-form-button"
//                   >
//                     Log in
//                   </Button>
//                   Or <a href="">register now!</a>
//                 </Form.Item>
//               </Form>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </Container>
//   );
// };

// export default SignIn;
