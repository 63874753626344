import { takeLatest, all } from "redux-saga/effects";
import API_AUTH from "../../services/Api_auth";
import API_DATA from "../../services/Api_data";
import API_SOAL from "../../services/Api_soal";
// import API_AUTH from "../../services/Api_auth"

/* ------------- Types ------------- */

import { TemplateTypes } from "../reducers/TemplateRedux";
import { AuthTypes } from "../reducers/AuthRedux";
import { CommentsTypes } from "../reducers/CommentsRedux";
import { RegisterTypes } from "../reducers/RegisterRedux";
import { EventTypes } from "../reducers/EventRedux";

/* ------------- Sagas ------------- */

import { templateRequest } from "./TemplateSagas";
import {
  signinUser,
  userSignOut,
  authForgotPasswordRequest,
  authResetPasswordRequest,
  authChangePasswordRequest,
} from "./AuthSagas";
import { getRequest } from "./CommentsSagas";
import { registerSiswaRequest } from "./RegisterSagas";
import {
  eventSiswaRequest,
  eventRequest,
  detailEventRequest,
  eventTerdaftarRequest,
  eventAktifRequest,
  adminDetailEventRequest,
  addEventRequest,
  deleteEventRequest,
  kodeEventRequest,
  cetakKodeRequest,
  adminPesertaEventRequest,
  getPesertaRequest,
  getDetailPesertaRequest,
  getSekolahRequest,
  // detailPesertarequest,
} from "./EventSagas";
import {
  getTreetesRequest,
  // getWarttegtesRequest,
  getPageRequest,
  getPsikotesRequest,
  getTimeRequest,
  jenisSoalRequest,
  nextPageRequest,
  refreshTimeRequest,
  saveJawabanRequest,
  saveTimeRequest,
  soalBerlangsungRequest,
  saveGrafikRequest,
  getAllSoalRequest,
  getSoalEventRequest,
  addSoalEventRequest,
  deleteSoalEventRequest,
  createSoalRequest,
  deleteSoalRequest,
  deleteAllSoalEventRequest,
  deleteAllPageRequest,
} from "./SoalSagas";
import { SoalTypes } from "../reducers/SoalRedux";
import { HasilTypes } from "../reducers/HasilRedux";
import {
  getHasilRequest,
  hasilGrafikRequest,
  hasilRequest,
  hasilTreeRequest,
  jawabanRequest,
  listHasilRequest,
  soalRequest,
  detailJawabanRequest,
  saveNilaiRequest,
} from "./HasilSagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api_auth = API_AUTH.create();
const api_auth = API_AUTH.create();
const api_data = API_DATA.create();
const api_soal = API_SOAL.create();
// const apisample = ApiSample.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  // //console.log('ROOT sagas JALAN !')
  yield all([
    takeLatest(TemplateTypes.TEMPLATE_REQUEST, templateRequest, api_auth),
    takeLatest(AuthTypes.SIGNIN_USER, signinUser, api_auth),
    takeLatest(AuthTypes.USER_SIGN_OUT, userSignOut, api_auth),
    takeLatest(
      AuthTypes.AUTH_FORGOT_PASSWORD_REQUEST,
      authForgotPasswordRequest,
      api_auth
    ),
    takeLatest(
      AuthTypes.AUTH_RESET_PASSWORD_REQUEST,
      authResetPasswordRequest,
      api_auth
    ),
    takeLatest(
      AuthTypes.AUTH_CHANGE_PASSWORD_REQUEST,
      authChangePasswordRequest,
      api_auth
    ),

    //Comment
    takeLatest(CommentsTypes.GET_REQUEST, getRequest, api_auth),

    takeLatest(
      RegisterTypes.REGISTER_SISWA_REQUEST,
      registerSiswaRequest,
      api_auth
    ),

    takeLatest(EventTypes.EVENT_SISWA_REQUEST, eventSiswaRequest, api_data),
    takeLatest(
      EventTypes.ADMIN_PESERTA_EVENT_REQUEST,
      adminPesertaEventRequest,
      api_data
    ),
    takeLatest(EventTypes.EVENT_REQUEST, eventRequest, api_data),
    takeLatest(EventTypes.DETAIL_EVENT_REQUEST, detailEventRequest, api_data),
    // takeLatest(
    //   EventTypes.DETAIL_PESERTA_REQUEST,
    //   detailPesertarequest,
    //   api_data
    // ),
    takeLatest(
      EventTypes.EVENT_TERDAFTAR_REQUEST,
      eventTerdaftarRequest,
      api_data
    ),
    takeLatest(EventTypes.EVENT_AKTIF_REQUEST, eventAktifRequest, api_soal),
    takeLatest(
      EventTypes.ADMIN_DETAIL_EVENT_REQUEST,
      adminDetailEventRequest,
      api_data
    ),
    takeLatest(EventTypes.ADD_EVENT_REQUEST, addEventRequest, api_data),
    takeLatest(EventTypes.DELETE_EVENT_REQUEST, deleteEventRequest, api_data),
    takeLatest(EventTypes.KODE_EVENT_REQUEST, kodeEventRequest, api_data),
    takeLatest(EventTypes.CETAK_KODE_REQUEST, cetakKodeRequest, api_data),
    takeLatest(EventTypes.GET_PESERTA_REQUEST, getPesertaRequest, api_data),
    takeLatest(
      EventTypes.GET_DETAIL_PESERTA_REQUEST,
      getDetailPesertaRequest,
      api_data
    ),
    takeLatest(EventTypes.GET_SEKOLAH_REQUEST, getSekolahRequest, api_data),

    takeLatest(SoalTypes.JENIS_SOAL_REQUEST, jenisSoalRequest, api_soal),
    takeLatest(SoalTypes.GET_PSIKOTES_REQUEST, getPsikotesRequest, api_soal),
    takeLatest(SoalTypes.GET_TREETES_REQUEST, getTreetesRequest, api_soal),
    // takeLatest(
    //   SoalTypes.GET_WARTTEGTES_REQUEST,
    //   getWarttegtesRequest,
    //   api_soal
    // ),
    takeLatest(SoalTypes.GET_PAGE_REQUEST, getPageRequest, api_soal),
    takeLatest(SoalTypes.DELETE_PAGE_REQUEST, deleteAllPageRequest, api_soal),
    takeLatest(
      HasilTypes.DETAIL_JAWABAN_REQUEST,
      detailJawabanRequest,
      api_soal
    ),
    takeLatest(SoalTypes.NEXT_PAGE_REQUEST, nextPageRequest, api_soal),
    takeLatest(SoalTypes.SAVE_JAWABAN_REQUEST, saveJawabanRequest, api_soal),
    takeLatest(SoalTypes.GET_TIME_REQUEST, getTimeRequest, api_soal),
    takeLatest(SoalTypes.SAVE_TIME_REQUEST, saveTimeRequest, api_soal),
    takeLatest(SoalTypes.REFRESH_TIME_REQUEST, refreshTimeRequest, api_soal),
    takeLatest(
      SoalTypes.SOAL_BERLANGSUNG_REQUEST,
      soalBerlangsungRequest,
      api_soal
    ),
    takeLatest(SoalTypes.SAVE_GRAFIK_REQUEST, saveGrafikRequest, api_soal),
    takeLatest(SoalTypes.GET_ALL_SOAL_REQUEST, getAllSoalRequest, api_soal),
    takeLatest(SoalTypes.GET_SOAL_EVENT_REQUEST, getSoalEventRequest, api_soal),
    takeLatest(SoalTypes.ADD_SOAL_EVENT_REQUEST, addSoalEventRequest, api_soal),
    takeLatest(
      SoalTypes.DELETE_SOAL_EVENT_REQUEST,
      deleteSoalEventRequest,
      api_soal
    ),
    takeLatest(
      SoalTypes.DELETE_ALL_SOAL_EVENT_REQUEST,
      deleteAllSoalEventRequest,
      api_soal
    ),
    takeLatest(SoalTypes.CREATE_SOAL_REQUEST, createSoalRequest, api_soal),
    takeLatest(SoalTypes.DELETE_SOAL_REQUEST, deleteSoalRequest, api_soal),

    takeLatest(HasilTypes.SOAL_REQUEST, soalRequest, api_soal),
    takeLatest(HasilTypes.JAWABAN_REQUEST, jawabanRequest, api_soal),
    takeLatest(HasilTypes.HASIL_REQUEST, hasilRequest, api_soal),
    takeLatest(HasilTypes.HASIL_TREE_REQUEST, hasilTreeRequest, api_soal),
    takeLatest(HasilTypes.GET_HASIL_REQUEST, getHasilRequest, api_soal),
    takeLatest(HasilTypes.LIST_HASIL_REQUEST, listHasilRequest, api_soal),
    takeLatest(HasilTypes.HASIL_GRAFIK_REQUEST, hasilGrafikRequest, api_soal),
    takeLatest(HasilTypes.SAVE_NILAI_REQUEST, saveNilaiRequest, api_soal),
  ]);
}
