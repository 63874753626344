import { React, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import "./tiutes.css";
import { useDispatch, useSelector } from "react-redux";
import HasilActions from "../../../../appRedux/reducers/HasilRedux";
import { Col, Row, Spin } from "antd";

const TiuTesHasil = () => {
  const hasilstate = useSelector((state) => state.hasil);
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  useEffect(() => {
    dispatch(
      HasilActions.soalRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
      })
    );
    dispatch(
      HasilActions.jawabanRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
        limit: 10000,
      })
    );
    dispatch(
      HasilActions.getHasilRequest({
        jenis_soal_id: params.jenisSoalId,
        event_id: params.eventId,
        m_user_id: params.mUserId,
      })
    );
  }, [params]);

  return (
    <>
      {hasilstate.fetching ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ padding: 25 }}>
          <Row>
            <Col
              span={24}
              style={{
                height: 50,
                backgroundColor: "black",
                marginBottom: 20,
                borderRadius: 20,
                border: "4px solid #0e7178",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: 10,
                  top: 0,
                  bottom: 0,
                  zIndex: 999999,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h5 style={{ color: "white" }}>
                  Score :{" "}
                  {hasilstate.payloadHasil && hasilstate.payloadHasil.nilai}%
                </h5>
              </div>

              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  alignItems: "center",
                  width: `${
                    hasilstate.payloadHasil && hasilstate.payloadHasil.nilai
                  }%`,
                  height: "100%",
                  background:
                    "radial-gradient(circle at top left, #0f165c, #097a82)",
                  // background:
                  //   "linear-gradient(to right, red 10%, purple 45%, blue 85%)",
                  left: 0,
                  top: 0,
                  paddingLeft: 10,
                }}
              ></div>
            </Col>
          </Row>
          {hasilstate.payloadSoal.map((e) => {
            return (
              // <Container
              //   key={e._id}
              //   header={false}
              //   title="tes inteligensi umum"
              // >
              <div
                style={{
                  background: "#fff",
                  border: "4px solid #0E7178",
                  borderRadius: "22px",
                  padding: "32px",
                  marginBottom: 20,
                }}
              >
                <Row
                  justify="space-between"
                  style={{
                    width: "100%",
                    marginBottom: 10,
                  }}
                >
                  <Col span={9}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "32%", textAlign: "center" }}>
                        <h6>A</h6>
                      </div>
                      <div style={{ width: "32%", textAlign: "center" }}>
                        <h6>B</h6>
                      </div>
                      <div style={{ width: "32%", textAlign: "center" }}>
                        <h6>C</h6>
                      </div>
                    </div>
                  </Col>
                  <Col span={13}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {[1, 2, 3, 4, 5].map((x) => {
                        return (
                          <div
                            key={x}
                            style={{ width: "19%", textAlign: "center" }}
                          >
                            <h6>{x}</h6>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                {e.soal_tiu_per_page.map((i) => {
                  return (
                    <Row
                      justify="space-between"
                      key={i._id}
                      style={{
                        width: "100%",
                        minHeight: 30,
                        marginBottom: 30,
                      }}
                    >
                      <Col span={9}>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            src={i.soal_gambar_tiu.gambar_a}
                            style={{
                              width: "32%",
                              objectFit: "contain",
                            }}
                          />
                          <img
                            src={i.soal_gambar_tiu.gambar_b}
                            style={{
                              width: "32%",
                              objectFit: "contain",
                            }}
                          />
                          <img
                            src={i.soal_gambar_tiu.gambar_c}
                            style={{
                              width: "32%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={13}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            textAlign: "center",
                          }}
                        >
                          {i.pilihan_jawaban_tiu.map((x) => {
                            const jawabanbenar =
                              hasilstate.payloadJawaban &&
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              ).length !== 0 &&
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              )[0].jawaban_tiu_tes.jawaban == x.kode &&
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              )[0].hasil == "BENAR";
                            const jawabansalah =
                              hasilstate.payloadJawaban &&
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              ).length !== 0 &&
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              )[0].jawaban_tiu_tes.jawaban == x.kode &&
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              )[0].hasil == "SALAH";
                            return (
                              <div key={x._id} style={{ width: "19%" }}>
                                <img
                                  src={x.gambar}
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    borderRadius: 5,
                                    border: "2px solid",
                                    borderColor:
                                      (jawabanbenar && "yellowgreen") ||
                                      (jawabansalah && "red") ||
                                      (!jawabanbenar &&
                                        !jawabansalah &&
                                        "transparent"),
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                      <Col
                        style={{ textAlign: "end", paddingRight: 40 }}
                        span={24}
                      >
                        <h6 style={{ color: "red" }}>
                          {hasilstate.payloadJawaban &&
                            hasilstate.payloadJawaban.filter(
                              (z) => z.soal_id == i._id
                            ).length !== 0 &&
                            hasilstate.payloadJawaban.filter(
                              (z) => z.soal_id == i._id
                            )[0].hasil != "BENAR" &&
                            `Jawaban benar : ${
                              hasilstate.payloadJawaban.filter(
                                (z) => z.soal_id == i._id
                              )[0].jawaban_tiu_tes.jawaban_benar
                            }`}
                        </h6>
                        <h6 style={{ color: "red" }}>
                          {hasilstate.payloadJawaban &&
                            hasilstate.payloadJawaban.filter(
                              (z) => z.soal_id == i._id
                            ).length == 0 &&
                            "Tidak menjawab"}
                        </h6>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TiuTesHasil;
