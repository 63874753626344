import CryptoJS from "crypto-js";

const secretKey = "my-secret-key";

export function encrypt(text) {
  return text
    ?.replace(/0/g, "hV5y001")
    .replace(/6/g, "a@xZY")
    .replace(/S/g, "00oIXc")
    .replace(/A/g, "66yHZ")
    .replace(/D/g, "Ju5")
    .replace(/T/g, "Jq8")
    .replace(/P/g, "7zX");
}

export function decrypt(text) {
  //   console.log(text);
  return text
    ?.replace(/hV5y001/g, "0")
    .replace(/a@xZY/g, "6")
    .replace(/00oIXc/g, "S")
    .replace(/66yHZ/g, "A")
    .replace(/Ju5/g, "D")
    .replace(/Jq8/g, "T")
    .replace(/7zX/g, "P");
}
