import _ from "lodash";
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  changeEventId: ["data"],
  changeJenisSoalId: ["data"],
  changeSkipIn: ["data"],
  changeJawabanSiswa: ["data"],
  popJawabanSiswa: ["array", "data"],
  changeUserCredent: ["data"],
});

export const LocalTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  event_id: null,
  jenis_soal_id: null,
  skip_in: null,
  jawaban_siswa: [],
  user_credent: null,
});

/* ------------- Selectors ------------- */

export const LocalSelectors = {
  getData: (state) => state.data,
};

/* ------------- Reducers ------------- */

// request the data from an api
export const eventId = (state, action) =>
  state.merge({ event_id: action.data });

export const jenisSoalId = (state, action) =>
  state.merge({ jenis_soal_id: action.data });

export const skipIn = (state, action) => state.merge({ skipIn: action.data });

export const jawabanSiswa = (state, action) =>
  state.merge({ jawaban_siswa: action.data });

export const popJawabanSiswa = (state, action) =>
  state.merge({
    jawaban_siswa: _.remove(action.array, (e) => {
      return e._id == action.data;
    }),
  });

export const user_credent = (state, action) =>
  state.merge({ user_credent: action.data });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_EVENT_ID]: eventId,
  [Types.CHANGE_JENIS_SOAL_ID]: jenisSoalId,
  [Types.CHANGE_SKIP_IN]: skipIn,
  [Types.CHANGE_JAWABAN_SISWA]: jawabanSiswa,
  [Types.POP_JAWABAN_SISWA]: popJawabanSiswa,
  [Types.CHANGE_USER_CREDENT]: user_credent,
});
